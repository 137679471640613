@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.container {
    position: relative;
    background-color: $background-secondary;
    border-radius: $form-checkbox-border-radius;
    display: block;
    cursor: pointer;
    align-items: stretch;
    margin-bottom: 1rem;
    overflow: hidden;
    border: 1px solid $border-color-light;

    li {
        padding: 0;
        margin: 0;
        list-style: none;

        &:not(:first-of-type) {
            margin: 0 0 0.3125rem;
        }
    }
}

.containerWide {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.hotelDetailsWrapper {
    padding: $hotel-item-padding;
    width: 100%;
}

.priceWrapper {
    position: absolute;
    top: $hotel-item-padding;
    right: $hotel-item-padding;
}

.recommendedBand {
    position: relative;
    z-index: 1;
    padding: 5px;
    background-color: $hotel-item-recommended-background;
    color: $hotel-item-recommended-color;
    max-width: 90%;
    font-size: $font-size-sm-1;
    height: 25px;
}

.recommendedBandLarge {
    max-width: 40%;
}

.recommendedBandWide {
    max-width: 60%;
}

.content {
    width: 100%;
}

.contentLarge {
    flex-direction: column;
}

.stars {
    margin-bottom: 0.5rem;
}

.starsHide {
    display: none;
}

.starsBlock {
    display: block;
}

.title {
    font-size: $font-size-base;

    margin: 0;
    padding: 0;
}

.titleHide {
    display: none;
}

.imageCrop {
    width: 40%;
}

.imageCropWide {
    width: 20%;
    margin-right: 1rem;
}

.imageContainer {
    background-color: $lightgrey;
    position: relative;
    height: 200px;

    @include respond-to('medium') {
        height: 180px;
    }
}

.imageContainerWide {
    width: 350px;
    height: unset;
    position: relative;

    & img {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.imageLarge {
    margin-bottom: 1rem;
}

.imageWide {
    max-width: 295px;
}

.selected {
    position: relative;
}

.selected::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid $hotel-item-checked-color;
    @include var-prop(
        'border-color',
        '--tco-form-checked-success-color',
        $hotel-item-checked-color
    );

    z-index: 2;
    pointer-events: none;
    border-radius: $form-checkbox-border-radius;
}

.selectedCheckbox {
    z-index: 2;
    width: 32px;
    height: 32px;
    position: absolute;
}

.checkedIconContainer {
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 2;

    svg {
        z-index: 1;
    }
}

.details {
    flex: 1;
    padding: 0;
    margin-top: 0.5rem;
}

.detailsLarge {
    p,
    a {
        margin: 0rem 0 0.25rem;
    }
}

.detailsWide {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;

    p,
    a {
        margin: 0 0 0.25rem;
    }
}

.distance {
    font-size: $font-size-sm-1;
    color: $secondary-color;
    margin: 0;
}

.comma {
    padding-right: 0.3rem;
    color: $secondary-color;
}

.commaHide {
    display: none;
}

.list {
    clear: both;
    list-style-type: none;
    padding: 0;
    margin: 0;
    font-size: $font-size-sm-1;

    @include respond-to('medium') {
        font-size: $font-size-base;
    }

    & li {
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;

        & svg {
            margin-right: 0.25rem;
            margin-top: 1px;
        }
    }

    & li:last-of-type {
        margin-bottom: 0;
    }
}

.listWide {
    padding-top: 0;
    margin-left: 12.8rem;
}

.detailButton {
    color: $hotel-item-checked-color;
    padding: 0;
    margin: 1rem 0.625rem 0.25rem;

    font-size: $body-font-size;
    cursor: pointer;
    font-weight: 700;

    @include respond-to('x-large') {
        font-size: $font-size-base;
    }

    &::after {
        content: ' ';
        display: inline-block;
        background-image: url('/images/arrow-right.svg');
        width: 5px;
        height: 9px;
        margin-left: 6px;
    }
}

.detailButtonWide {
    margin: 0.25rem 0;
}

.detailButtonLarge {
    margin-top: 0.75rem;
}

.recommended {
    background: $success-color;
    padding: 4px 6px;
    color: white;
    font-weight: bold;
    display: inline-flex;
    margin-bottom: 6px;
    font-size: $font-size-sm-2;
    border-radius: $border-radius-base;
}

.moreInfoPriceContainer {
    margin-top: 1rem;
    margin-bottom: 0;
}

.breakfast {
    display: flex;
    align-items: center;

    & > svg {
        margin-right: 5px;
    }
}
