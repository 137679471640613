@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.stepIcon {
    display: flex;
    align-items: center;
    align-self: center;

    & svg {
        fill: $green;
        stroke: $green;
        & circle {
            stroke: $green;
        }
    }
}

.successCheckIcon {
    background: $green;
}
