@import '../../../app/styles/variables';

.wrap {
    position: relative;
    padding: 1rem 1.25rem 1rem 3.25rem;
    font-size: $font-size-base;
    background-color: $background-error;
    border-left: 0.25rem solid $error-color;
    border-radius: $border-radius-base;

    &:after {
        position: absolute;
        left: 1rem;
        top: 1rem;
        height: 1.5rem;
        width: 1.5rem;
        background-image: url('/images/icon-error.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        content: '';

        @include respond-to('medium') {
            left: 1.25rem;
        }
    }

    margin-bottom: 0.625rem;

    @include respond-to('medium') {
        margin-bottom: 1.25rem;
    }

    @include respond-to('medium') {
        padding: 1.125rem 1.25rem 1.125rem 4rem;
    }
}

.missingFields {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    line-height: 1.5;
}

.field {
    padding-right: 1rem;
    color: $blue;
    list-style: none;

    &::first-letter {
        text-transform: capitalize;
    }
}
