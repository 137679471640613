@import "../../../app/styles/variables";

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.root {
    animation: spin 1s infinite linear;
    transform-origin: 50% 50%;
}

.spinnerPathLight {
    fill: $text-alternate-color;
}

.spinnerPathDark {
    fill: $text-primary-color;
}
