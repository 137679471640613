@import '../../../app/styles/variables';

$checkBoxSize: 1.1875rem;

.root {
    display: inline-flex;
    position: relative;

    // nest to overwrite defaults
    .input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
        display: block;
    }
}

.root + .root {
    margin-left: 1.75rem;
}

.svg {
    width: $checkBoxSize;
    min-width: $checkBoxSize;
    height: $checkBoxSize;
    margin-right: 0.375rem;
    display: block;

    .checkMark {
        display: none;
        fill: $blue;
    }

    .outer {
        fill: $alternate-color;
        stroke: $light-stone;
    }
}

@keyframes scaleIn {
    0% {
        transform: translate(1.5px, 2.5px) scale(0);
    }
    75% {
        transform: translate(1.5px, 2.5px) scale(0.9);
    }
    100% {
        transform: translate(1.5px, 2.5px) scale(0.8);
    }
}

.input:checked ~ .svg {
    .checkMark {
        display: block;
        animation: scaleIn 0.2s ease-in-out;
        transform-origin: 50% 50%;
        transform: translate(1.5px, 2.5px) scale(0.8);
    }

    .outer {
        stroke: $blue;
    }
}

.label {
    display: inline-flex;
    align-items: center;
    line-height: $font-size-sm-1;
    font-size: $font-size-sm-1;

    @include respond-to('medium') {
        font-size: $font-size-base;
        line-height: $checkBoxSize;
    }

    & svg {
        margin-right: 0.5rem;
    }

    a {
        position: relative;
        z-index: 1;
        color: $blue;
        &:hover {
            text-decoration: underline;
        }
    }
}
