@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.title {
    margin: 0 0 1rem 0;

    font-weight: 700;
    font-size: $font-size-base;
    @include var-prop('color', '--tco-foreground-light', #fff);
}

.darkTitle {
    @include var-prop('color', '--tco-body-color', $text-primary-color);
}

.list {
    padding: 0;
    margin: 0;
    margin-bottom: -$why-equipo-space-between-items;
    list-style-type: none;

    button {
        padding: 0;
        width: 100%;

        &:not(:last-of-type) {
            margin-bottom: 1rem;
        }
    }
}

.listItem {
    display: flex;
    align-items: center;
    list-style: none;
    font-size: $font-size-base;

    margin-bottom: $why-equipo-space-between-items;

    & svg {
        & path {
            @include var-prop('fill', '--tco-foreground-light', #fff);
        }
    }
}

.darkListItem {
    @include var-prop('color', '--tco-body-color', $text-primary-color);

    & svg {
        & path {
            @include var-prop('fill', '--tco-body-color', $text-primary-color);
        }
    }
}

.icon {
    margin-right: 0.625rem;
    display: flex;
    align-items: center;
}

.whyPartnerLink {
    margin-left: 0.5rem;
    @include var-prop('color', '--tco-foreground-light', #fff);
    font-size: $font-size-sm-2;
    text-decoration: underline;
}
