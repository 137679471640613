@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties.scss';

$gutter: 0.625rem;

.contactLink {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 1rem;
    margin-top: $gutter;
    margin-bottom: $gutter;
    border-radius: 3px;
    background: $background-primary;

    &:visited {
        color: $secondary-color;
    }

    &:hover {
        cursor: pointer;
    }
}

.whiteBackground {
    background: white;
}

.iconWrapper {
    padding: 7px 9px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    background: $blue;
    flex-shrink: 0;

    & svg {
        width: 18px;
        fill: white;
    }
}

.contactLinkGreyText {
    color: $stone;

    display: flex;
    align-items: center;
}

.contactLinkTextWrapper {
    flex: 1;
    flex-wrap: wrap;
    margin-left: 1rem;
    display: flex;
    align-items: center;
    font-size: $font-size-sm-1;
    overflow: hidden;
    border-radius: $border-radius-base;
}

.contactLinkText {
    margin-right: 0.5rem;
    color: $text-primary-color;
    line-height: 25px;
    font-weight: bold;
}

.rightIcon {
    display: flex;
    justify-content: center;
    align-items: center;
}
