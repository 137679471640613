@import '../../../app/styles/variables';

.row {
    width: 100%;
    display: grid;
    grid-template-columns: none;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;

    @include respond-to('medium') {
        grid-template-columns: 1fr 1fr;
    }

    @include respond-to('x-large') {
        grid-template-columns: 1fr 1fr 1fr;

        .colSpanTwo {
            grid-column: span 2;
        }
    }

    > h1 {
        margin: 0;
    }

    &:first-of-type {
        padding-top: 0;

        @include respond-to('medium') {
            padding-top: 0;
        }

        @include respond-to('large') {
            padding-top: 0;
        }
    }

    &:last-of-type {
        @include respond-to('large') {
        }
    }
}

.options {
    @include respond-to('medium') {
    }

    @include respond-to('x-large') {
    }

    &:not(:first-of-type) {
        @include respond-to('medium') {
            margin-top: 0;
        }
    }
}

.optionsMobile {
    @include respond-to('medium') {
        display: none;
    }
}

.optionsTablet {
    display: none;

    @include respond-to('medium') {
        display: block;
    }
}

.popoverImg {
    margin-bottom: -0.15rem;
    margin-left: 0.625rem;
    width: 1rem;
    height: 1rem;
}

.childrenAges {
    display: block;
    clear: both;
    padding-bottom: 1rem;
}

.infoImage {
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='13px' height='13px' viewBox='0 0 13 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3EGroup 4 Copy 2%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='even'%3E%3Cg id='Group-4-Copy-2'%3E%3Cg id='Group-8'%3E%3Ccircle id='Oval-Copy-7' fill='%238C98B1' cx='6.5' cy='6.5' r='6.5'%3E%3C/circle%3E%3Ctext id='i' font-family='RobotoCondensed-Bold, Roboto Condensed' font-size='9.75' font-style='condensed' font-weight='bold' fill='%23FFFFFF'%3E%3Ctspan x='5.27172852' y='10'%3Ei%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    margin: -3px 0 0 0.3rem;
}

.submitting {
    filter: blur(15px);
}

.spinnerWrap {
    position: absolute;
    height: 100vh;
    left: 50%;
    top: 50%;
    z-index: 999;
}

.seatsTextWrapper span {
    display: block;
}

.seatsText {
    font-weight: 500;
    color: $success-color;
    font-size: $font-size-sm-1;
    margin-top: 37px;
    display: flex;
    align-items: center;

    & svg {
        margin-right: 4px;
    }

    @include respond-to-reverse('medium') {
        margin-top: 0;
    }
}

.seatsSubText {
    font-size: $font-size-sm-1;
    margin-top: 4px;
    color: $stone;
}

.uspNotificationContainer {
    margin-bottom: 24px;
}

.travellerWithHotelControlsPopoverButton {
    margin-left: 0.25rem;
}
