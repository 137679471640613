@import '../../../app/styles/variables';

.base {
    @include respond-to('medium') {
    }

    @include respond-to('large') {
    }
}

.list {
    margin: 0;
    padding: 0;
    margin-top: 1rem;
}
