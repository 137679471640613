@import '../../../app/styles/variables';

.field {
    border: $input-border-size solid $input-border-color;
    padding: 0 0.75rem;
    width: 15rem;
    height: 50px;
    border-radius: $input-border-radius;
    overflow: hidden;
    display: block;
    margin-right: 0.625rem;
    font-size: 1rem;
}

.errorMessage {
    background: $background-error;
    color: $error-color;
    border-radius: $border-radius-base;
    padding: 0.375rem 0.625rem;
    margin: 0.625rem 0.625rem 0 0;
    width: 15rem;
    font-size: $font-size-sm-1;
}

.field:not(.isPristine):not(.noValidateError):invalid,
.fieldLabel {
    display: flex;
    align-items: flex-start;
    clear: both;
    margin: 0.5rem 0;
    font-weight: $input-label-font-weight;
    font-size: $input-label-font-size;
}

.small .small + .errorMessage {
    width: 5.625rem;
}

.postcode {
    width: 8.75rem;
}

.medium,
.medium + .errorMessage {
    width: 100%;

    @include respond-to('medium') {
        width: 15rem;
    }
}

.actionCode {
    width: 10rem;
}

.optional {
    margin-top: 0.1rem;
    margin-left: 0.2rem;
    color: $secondary-color;
    font-size: $font-size-sm-1;
}

.large,
.large + .errorMessage {
    width: 15rem;

    @include respond-to-reverse('medium') {
        width: 100%;
    }
}

.invalid {
    border-color: $error-color;
}

.valid {
    border-color: $success-color;
    border-width: $border-valid-width;
}

.noBorder {
    border: none;
    padding: 0 0.625rem;
    &.field:not(.isPristine):invalid,
    .invalid {
        border: none;
    }

    &.field:not(.isPristine):valid {
        border: none;
    }

    &.field {
        height: 2.2rem;
    }
}

.prefix {
    padding-left: 0.625rem;
    line-height: initial;
}

.fullWidthInput {
    width: 100%;
}
