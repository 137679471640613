@import '../../../app/styles/variables';

.detailsTable {
    & tr:last-of-type {
        & td {
            padding-bottom: 0;
        }
    }

    & tr:first-of-type {
        & td {
            padding-top: 0;
        }
    }

    & td {
        padding: 0.437rem 0;
    }

    & td:first-of-type {
        display: flex;
        align-items: flex-start;
        white-space: nowrap;
    }

    & td:last-of-type {
        padding-left: 2rem;

        @include respond-to('large') {
            padding-left: 1rem;
        }
    }
}

.detailsButton {
    padding: 0;
    color: $blue;

    &:hover {
        cursor: pointer;
    }
}

.eventDate {
    margin-right: 0.5rem;
}
