@import '../../../app/styles/variables';

.wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 0.5rem;

    & svg:not(:last-of-type),
    img {
        width: 36px;
        height: auto;
    }

    @include respond-to('medium') {
        margin: 0;
    }

    @include respond-to('large') {
        padding-top: 0;
    }
}

.wrapIconLarge {
    & svg:not(:last-of-type),
    img {
        width: 75px;
    }
}

.wrapFullWidth {
    width: 100%;
}
