@import '../../../app/styles/variables';

.noPrices {
    display: none;
    padding: 1.5rem 2rem;
    color: $secondary-color;
    text-align: center;
    line-height: 2;
}

.line {
    border: 0;
    border-top: 1px solid $dark-light-color;
    margin: 0 0.625rem;
}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0.875rem 0;
    color: $secondary-color;
    border-bottom: 1px solid rgba($light-stone, 0.7);
    p {
        font-size: $font-size-sm-1 !important;
    }

    @include respond-to-reverse('medium') {
        padding: 1rem 0rem 1rem;
    }
}

.listSubtotal {
    padding-top: 0;
}

.discountList {
    padding-top: 0;
    border-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.discountContainer {
    border-bottom: 1px solid rgba($light-stone, 0.7);
}

.discountError {
    padding: 0 0.625rem 0.875rem 0.625rem;

    @include respond-to-reverse('medium') {
        padding: 0rem 0rem 1rem;
    }
}

.discountTravelPart {
    display: flex;
    align-items: center;
    color: #1a1b33;

    & svg {
        margin-right: 1.3rem;
    }
}

.listItem {
    display: flex;
    justify-content: space-between;
    clear: both;
    font-size: $font-size-sm-1;
    align-items: center;

    &:not(:first-of-type) {
        padding-top: 0.875rem;
    }
}

.alternativeListItem {
    font-size: $font-size-sm-1;
    margin-top: 14px;
}

.alternateTitle {
    margin-bottom: 1rem;
    margin-top: 0.875rem;
}

.travelPart {
    flex: 1;
    line-height: 1.5;
    color: $secondary-color;
    font-weight: 500;
}

.cost {
    width: auto;
    text-align: right;
}

.costPositive {
    color: $green;
}

.infoImage {
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='13px' height='13px' viewBox='0 0 13 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3EGroup 4 Copy 2%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-4-Copy-2'%3E%3Cg id='Group-8'%3E%3Ccircle id='Oval-Copy-7' fill='%238C98B1' cx='6.5' cy='6.5' r='6.5'%3E%3C/circle%3E%3Ctext id='i' font-family='RobotoCondensed-Bold, Roboto Condensed' font-size='9.75' font-style='condensed' font-weight='bold' fill='%23FFFFFF'%3E%3Ctspan x='5.27172852' y='10'%3Ei%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    margin: -0.2rem 0 0 0;

    @include respond-to('large') {
        margin: -0.2rem 0.3rem 0;
    }
}

.important {
    display: grid;
    grid-template-columns: 3fr 1fr;
    font-size: $font-size-base;
    text-align: left;
    padding: 0.875rem 0 0 0;
    margin: 0;
    align-items: center;

    @include respond-to-reverse('medium') {
        padding: 0.625rem 0 0 0;
    }
}

.totalForPersons {
    padding-right: 0.6rem;
}

.totalCostsWrapper {
    text-align: right;
}

.totalCosts {
    color: $valid-color;
    font-size: $font-size-lg-4;
    font-weight: $font-weight-medium;

    width: 100%;
    margin-top: 0.3rem;
    margin-bottom: 1rem;

    @include respond-to('x-large') {
        margin-top: -0.625rem;
        width: auto;
        margin-bottom: 0;
    }
}

.totalSubtitle {
    font-size: 0.8rem;
    color: $green;
    margin-top: 0rem;
}

.paymentDetail {
    padding-top: 0.625rem;
}

.percentagePrice {
    font-size: $font-size-sm-1;
    color: $valid-color;
}

.blue {
    float: right;
}

.coupon {
    padding: 0.625rem;
    color: $info-color;
}

.secure {
    text-align: left;
    padding: 0.875rem 0 0.875rem 0;
    color: $valid-color;

    font-size: $font-size-sm-1;
    border-top: 1px solid rgba($light-stone, 0.7);

    img {
        margin-right: 0.5rem;
    }
}

.code {
    text-align: left;
    padding: 0.625rem;
    padding-top: 0rem;
    color: $info-color;
    font-size: $font-size-sm-1;
}

.payments {
    display: flex;
    align-items: center;
}

.discountAddIcon:hover {
    cursor: pointer;
}

.popoverButton {
    position: relative;
    top: 3px;
    margin-left: 0.25rem;
}
