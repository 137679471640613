@import '../../../app/styles/variables';

.wrap {
    @include respond-to('large') {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100vh;
        width: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        z-index: 10;
    }
}

.header {
    margin: 1rem 4rem 2rem 0.625rem;
    font-size: $font-size-lg-5;

    @include respond-to('medium') {
        margin-top: 0;
        margin-left: 0;
        font-size: $font-size-lg-7;
    }

    @include respond-to('large') {
        font-size: $font-size-lg-6;
    }
}

.background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: $background-alternate;
    opacity: 0.5;

    @include respond-to('large') {
        position: absolute;
    }
}

.roomLayout {
    position: fixed;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: $background-secondary;
    overflow-y: scroll;
    z-index: 10;
    -webkit-overflow-scrolling: touch;

    @include respond-to('large') {
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: auto;
        width: 28.125rem;
        min-height: 30rem;
        max-height: 90vh;
        margin: 0;
        border-radius: $border-radius-base;
        transform: translate(-50%, -50%);
    }
}

.roomLayoutContent {
    position: relative;
    flex: 1;
    overflow-y: scroll;
    padding: 0.625rem 0.625rem 7.25rem;
    padding: 0.625rem 0.625rem calc(env(safe-area-inset-bottom, 1.625rem) + 5.625rem);

    @include respond-to('medium') {
        position: relative;
        overflow: auto;
        height: 80%;
        padding: 1.875rem;
        padding: 1.875rem 1.875rem env(safe-area-inset-bottom, 1.625rem);
    }

    @include respond-to('large') {
        padding: 1.875rem;
    }
}

.room {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    align-self: start;

    @include respond-to('medium') {
        display: block;
    }

    @include respond-to('large') {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.text {
    margin: 0 0 0.5rem 0;
    color: $secondary-color;

    font-size: $font-size-base;
    font-weight: 500;
    float: none;

    @include respond-to('medium') {
        padding: 0;
    }

    @include respond-to('large') {
        width: 50%;
    }
}

.firstSelector {
    display: inline-block;
    width: 100%;
}

.selector {
    display: inline-block;
    width: 100%;
    margin-top: 0.625rem;

    @include respond-to('medium') {
        margin-top: 0;
    }
}

.error {
    color: $error-color;
    text-align: center;
    margin-bottom: 10px;
}

.confirmButton {
    padding: 0.625rem 0.625rem 0.625rem;
    padding: 0.625rem 0.625rem calc(env(safe-area-inset-bottom, 0.625rem) + 0.625rem);
    width: 100%;
    background-color: $background-secondary;
    display: flex;
    flex-direction: column;
    align-items: center;

    & button {
        margin: 0;
    }
}
