@import '../../../app/styles/variables';

$gutter: 0.625rem;

.base {
    position: fixed;
    padding: 1.25rem $gutter $gutter;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $background-secondary;
    overflow-y: scroll;
    z-index: 99;

    @include respond-to('medium') {
        padding: 1.875rem;
    }
}

.title {
    margin: 9px 0 1.875rem;
    padding: 0 3rem 0 $gutter;

    @include respond-to('medium') {
        font-size: $font-size-lg-7;
    }
}

.content {
    @include respond-to('medium') {
        display: flex;
    }
}

.block {
    margin-bottom: $layout-spacing-xl;

    @include respond-to('medium') {
        width: 50%;
        margin-bottom: 0;
    }

    &:first-of-type {
        @include respond-to('medium') {
            padding-right: 1rem;
            margin-right: 1rem;
            border-right: 1px solid $light-stone;
        }
    }
}

.intro {
    display: flex;
    padding: 0 $gutter;
    margin-bottom: 2rem;
}

.employee {
    position: relative;
    height: 3.75rem;
    width: 3.75rem;
    flex-shrink: 0;
}

.imgWrap {
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 2rem;
    overflow: hidden;
}
.img {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.status {
    box-sizing: initial;
    position: absolute;
    height: 0.7rem;
    width: 0.7rem;
    top: 0.4rem;
    right: -0.2rem;
    border: $border-width-lg solid $background-secondary;
    border-radius: 1rem;
}

.statusOffline {
    background-color: $error-color;
}

.statusOnline {
    background-color: $success-color;
}

.text {
    flex: 1;
    margin: 0 0 0 1.0625rem;
    line-height: $line-height-base;
    font-size: 1rem;
}

.secondText {
    margin-top: 1rem;
}

.phoneNumber {
    color: $blue;
    margin-left: 0.3rem;
}

.timezone {
    font-size: $font-size-sm-1;
    color: $darkGrey;
}

.supportText {
    margin-top: 2rem;
}
