// Normal
@font-face {
    font-family: "DINPro";
    src: url("fonts/DINPRO/DINPro-Regular.woff") format('woff'),
         url("fonts/DINPRO/DINPro-Regular.woff2") format("woff2");
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "DINPro";
    src: url("fonts/DINPRO/DINPro-Medium.woff") format('woff'),
         url("fonts/DINPRO/DINPro-Medium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "DINPro";
    src: url("fonts/DINPRO/DINPro-Bold.woff") format('woff'),
         url("fonts/DINPRO/DINPro-Bold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

// Condensed
@font-face {
    font-family: "DINPro Condensed";
    src: url("fonts/DINPRO/DINPro-CondensedRegular.woff") format('woff'),
         url("fonts/DINPRO/DINPro-CondensedRegular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "DINPro Condensed";
    src: url("fonts/DINPRO/DINPro-CondensedMedium.woff") format('woff'),
         url("fonts/DINPRO/DINPro-CondensedMedium.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "DINPro Condensed";
    src: url("fonts/DINPRO/DINPro-CondensedBold.woff") format('woff'),
         url("fonts/DINPRO/DINPro-CondensedBold.woff2") format("woff2");
    font-weight: 700;
    font-style: normal;
    font-display: block;
}
