@import '../../../app/styles/variables';

.container {
    padding: 0.625rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.225rem;

    @include respond-to-reverse('large') {
        display: block;
    }
}

.contentLeft {
    flex: 2;
}

.contentRight {
    width: 398px;
    margin-left: 1rem;
    flex-shrink: 0;
    flex-grow: 0;

    @include respond-to-reverse('large') {
        margin-top: 2.250rem;
        width: 100%;
        margin-left: 0;
    }
}



