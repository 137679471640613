@import '../../../app/styles/variables';

@keyframes fadeInWithBounce {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInWithBounceBottom {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.wrap {
    display: inline-block;
    position: relative;
}

.button {
    display: flex;
    align-items: center;
    padding: 0;

    &:hover {
        cursor: pointer;
    }

    &::after,
    &::before {
        display: none;
        content: '';
        position: absolute;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        width: 14px;
        height: 14px;
        background: white;
    }

    &::after {
        box-shadow: -0.125rem -0.125rem 0.3rem rgb(0 0 0 / 10%);
    }

    &::before {
        z-index: 99;
    }
}

.buttonOpen {
    &::after,
    &::before {
        display: inline-block;
        animation: fadeInWithBounceBottom 0.3s ease-in;
    }
}

.tooltip {
    display: none;
    margin-top: 0.625rem;
    left: -30px;
    z-index: 98;
    position: absolute;
    width: max-content;
    min-width: 140px;
    max-width: 380px;
    height: auto;
    padding: 0.5rem 0.8rem;
    background-color: $background-secondary;
    border-radius: $border-radius-lg;

    font-size: $font-size-sm-1;
    font-weight: normal;
    color: $dark-color;
    box-shadow: 0 0 0.3rem 0 rgba(0, 0, 0, 0.1);
    line-height: 1.3;

    animation: fadeInWithBounceBottom 0.1s ease-in;
}

.tooltipOpen {
    display: block !important;
}

.modalSvgTitle {
    & svg {
        height: 1rem;
        width: auto;
        margin-right: 0;
    }
}
