@import '../../../app/styles/variables';

.content {
    position: relative;
}

.header {
    margin-top: 1rem;

    @include respond-to('medium') {
        padding-right: 2rem;
        margin: 0;
        background-color: $background-secondary;
        z-index: 11;
    }
}

.headerContainer {
    background: white;
    z-index: 99;
    width: 100%;
    padding: 2rem 2rem 1rem 2rem;

    @include respond-to-reverse('medium') {
        padding: 1rem 0.625rem 1rem 0.625rem;
    }

    @include respond-to('medium') {
        position: sticky;
        top: 0;
    }
}

.background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: $background-alternate;
    opacity: 0.5;
    z-index: 30;
}

.morePhotos {
    cursor: pointer;
    color: $blue;
    padding: 0;
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: left;

    &:hover {
        text-decoration: underline;
    }
}

.stars {
    margin: 0.625rem;

    @include respond-to('medium') {
        margin: 1rem 0 0 0;
        width: 41rem;
        padding-top: 4rem;
        position: fixed;
        z-index: 10;
        padding-bottom: 1rem;
        background-color: $background-secondary;
    }
}

.roomLayout {
    position: fixed;
    width: 100%;
    overflow: scroll;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: $background-secondary;
    z-index: 31;
    -webkit-overflow-scrolling: touch;

    @include respond-to('large') {
        width: 43.75rem;
        top: 8rem;
        bottom: 1.875rem;
        border-radius: $border-radius-base;
    }
}

.modalBody {
    padding: 0.625rem;

    @include respond-to('medium') {
        padding: 0 1.875rem 2.5rem 1.875rem;
    }
}

.topRow {
    width: 100%;

    @include respond-to('medium') {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
    }
}

.spacing {
    width: 1rem;
    height: 1rem;
    flex-grow: 0;
    flex-shrink: 0;
}

.topImages {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
}

.topImage {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    object-fit: cover;
}

.topImageWrap {
    width: 100%;
    height: 100%;
}

.bottomImages {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;

    @include respond-to-reverse('medium') {
        grid-template-columns: 1fr;
    }
}

.bottomImage {
    background: $lightgrey;
    display: flex;

    & img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.map {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-height: 200px;

    @include respond-to('medium') {
        min-height: 300px;

        & > div {
            flex: 1;
        }
    }
}

.legend {
    display: flex;
    align-items: center;
    float: right;
    margin: 0;
    margin-top: 1rem;
    color: $secondary-color;
    background-color: $background-secondary;
}

.legendIcon {
    display: inline-block;
    margin-right: 0.5rem;
    height: 1rem;
    width: 1rem;
    background-color: rgba($sea, 0.1);
    border: $border-width-lg solid rgba($sea, 0.5);
    border-radius: 1rem;
}

.features {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 0.625rem;

    & ul {
        margin-top: 0;
    }

    @include respond-to('medium') {
        padding: 0;
        flex-wrap: nowrap;
    }
}

.features > div {
    width: 100%;

    @include respond-to('medium') {
        width: 48%;
    }
}

.pictures {
    display: inline-block;
}

.detailText {
    padding: 0;
}

.text {
    font-size: $font-size-base;
    line-height: $line-height-base;
    float: none;
}

.optionTitle {
    color: $secondary-color;
    font-size: $font-size-lg-2;
}

.randomImages {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.randomImages > div {
    width: 48%;
    margin: 0;
    padding-bottom: 1rem;
}

.randomImages > div > img {
    padding: 0;
    margin: 0;
    width: 100%;
}

.features ul {
    list-style-type: none;
    padding: 0;
}

.features li {
    vertical-align: middle;
    line-height: 1;
    font-size: $font-size-sm-1;

    @include respond-to('medium') {
        font-size: $line-height-sm;
    }
}

.blackText {
    color: $dark-color;
    font-size: 1rem;
}

.lightText {
    color: $secondary-color;
    margin-left: 0.25rem;
    font-size: 1rem;
}

.closeButtonHotel {
    z-index: 11;
    background-color: $background-secondary;
    position: absolute;
    right: 0;
    top: 0;
}

.spinnerWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
}

.headerDetailsContainer {
    display: flex;
}

.price {
    @include respond-to('medium') {
        flex: 1;
        padding-right: 0;
    }

    padding-right: 4rem;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.priceText {
    margin-left: 2rem;
}

.verticalDivider {
    width: 1px;
    background: $lightgrey;

    @include respond-to-reverse('medium') {
        display: none;
    }
}

.title {
    flex: 2;
}

.navigation {
    position: absolute;
    top: 50%;
    margin-top: -7px;
    z-index: 99;

    &:hover {
        cursor: pointer;
    }
}

.navigationPrev {
    left: 15px;
    transform: rotate(180deg);
}

.navigationNext {
    right: 15px;
}

.distanceBreakfastWrapper {
    display: flex;

    & div {
        flex: 1;
    }
}

.slideImageStyle {
    width: 100%;
    height: 100%;
}

.slideImageFitContain {
    object-fit: contain;
}

.slideImageFitCover {
    object-fit: cover;
}
