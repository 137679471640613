@import '../../../app/styles/variables';

.wrap {
    display: flex;
    align-items: center;
}

.title {
    margin-right: 0.625rem;
}

.slider {
    appearance: none;
    padding: 0.1875rem;
    height: 1.5rem;
    width: 2.75rem;
    background-color: $background-secondary;
    border: $border-width-base solid $light-stone;
    border-radius: 1rem;
    box-shadow: 0 1px 2px rgba($dark-color, 0.06);
    cursor: pointer;
}

.sliderThumb {
    height: 1rem;
    width: 1rem;
    background-color: $secondary-color;
    background-image: url('/images/icon-cross.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 50%;
    border-radius: 1rem;
    transition: all 0.3s ease;
}

.sliderThumbActive {
    margin-left: 55%;
    background-color: $blue;
    background-image: url('/images/icon-checkmark.svg');
    background-size: 75%;
}
