@import '../../../app/styles/variables';

.grey {
    color: $stone;
}

.formLabel {
    margin-bottom: 0.5rem;
    font-weight: 700;
    font-size: $font-size-sm-1;

    @include respond-to('medium') {
        font-size: $font-size-base;
    }
}

.marginTop {
    margin-top: 0.5rem;
}
