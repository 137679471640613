@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.formrow {
    display: inline-block;
    margin-top: -1px;
    margin-left: 0px;
    overflow: hidden;
    width: 20%;
    height: 3.125rem;
    vertical-align: top;
}

.selected {
    border-bottom: 3px solid $filter-select-option-selected-color;
    @include var-prop(
        'border-color',
        '--tco-form-checked-success-color',
        $filter-select-option-selected-color
    );
}

.secondary.selected {
    border-bottom: 3px solid $filter-select-option-selected-secondary-color;
    @include var-prop(
        'border-color',
        '--tco-form-checked-success-color',
        $filter-select-option-selected-color
    );
}

.checklabel {
    display: block;
    width: 100%;
    height: 100%;
    border-left: 1px solid $dark-light-color;
    padding: 0.5rem 1rem;
    color: $dark-color;
    text-align: center;
    font-size: $font-size-sm-1;
    line-height: $line-height-sm;
    cursor: pointer;
}

.checkLabelBold {
    font-weight: 500;
}

.noSubtitle {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.formrow:first-of-type > .checklabel {
    border-left: 0px;
}

.checklabel > small {
    color: $secondary-color;
    font-size: inherit;
}

.checklabel:hover {
    background: #ddd;
}
.checkbox {
    display: none;
}
.checkbox:focus + label {
    border-color: $blue;
}
.checkbox:active + label {
    background-color: #ddd;
}

/*
.checkbox:checked + label {
    border-bottom: 3px solid $valid-color;
}
*/

.subTitle {
    display: block;
}

.price {
    text-align: right;
    float: right;
    clear: both;
    margin-right: 0px;
}
