@import '../../../app/styles/variables';

.teaserBanner {
    display: inline-block;
    background-color: $tertiary-color;
    padding: 0.6875rem 1rem 0.6875rem 2.4375rem;
    color: $alternate-color;
    font-size: $font-size-base;
    line-height: 1.5;
    border-radius: $border-radius-base;
    background-position: left center;
    background-repeat: no-repeat;
    background-position-x: 0.625rem;
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='21px' height='21px' viewBox='0 0 21 21' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3EGroup 5%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='VO' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='01-ticket-XL' transform='translate(-59.000000, -644.000000)'%3E%3Cg id='Group-5' transform='translate(60.000000, 645.000000)'%3E%3Ccircle id='Oval-Copy-7' stroke='%23FFFFFF' cx='9.5' cy='9.5' r='9.5'%3E%3C/circle%3E%3Ctext id='i' font-family='RobotoCondensed-Bold, Roboto Condensed' font-size='13' font-style='condensed' font-weight='bold' fill='%23FFFFFF'%3E%3Ctspan x='8' y='14'%3Ei%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
