@import '../../../app/styles/variables';

.progressCard {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: $background-secondary;
    overflow: hidden;
    padding: 0.875rem;
    align-items: center;
    box-shadow:
        rgba(17, 17, 26, 0.1) 0px 8px 24px,
        rgba(17, 17, 26, 0.1) 0px 16px 56px,
        rgba(17, 17, 26, 0.1) 0px 24px 80px;

    @include respond-to('large') {
        display: none;
    }
}

.eventInfo {
    display: block;
    width: 70%;
    padding: 1rem;

    @include respond-to-reverse('large') {
        flex: 1;

        & p:first-of-type {
        }
    }
}

.eventInfo > p {
    margin: 0;
}

.eventInfo > p:first-child {
    padding: 0;
    font-size: $font-size-lg-1;
    line-height: 1.4;
    font-weight: bold;
}

.eventInfo > p:last-child {
    margin-top: 0.5rem;
    padding: 0;
    color: $darkblue;
}

.sellPoint {
    background-color: $primary-color;
    padding: 0.25rem 0.3125rem;
    color: $alternate-color;
    border-radius: 0.125rem;
    font-size: $font-size-sm-1;

    margin: 0.5rem;
    display: none;

    @include respond-to('medium') {
        display: inline;
    }
}

.totalCosts {
    margin: 0;
    padding: 0;
    font-size: $font-size-base;
    vertical-align: middle;
    font-weight: bold;

    @include respond-to-reverse('large') {
        font-size: $font-size-lg-3;
    }
}

.button {
    color: $blue;
    text-align: center;
    font-size: $font-size-sm-1;
    font-weight: bold;

    &:hover {
        cursor: pointer;
    }
}

.dropdown-icon {
    margin-left: 0.3rem;
}

.travelDetails {
    color: $secondary-color;
    font-size: $font-size-sm-2;
    text-align: right;
}

.chevronDown {
    margin-left: 5px;

    & svg {
        transform: rotate(180deg);
        & path {
            stroke: $blue;
            fill: $blue;
        }
    }
}
