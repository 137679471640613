@import '../../styles/variables';

.headerInfo {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.chooseSeatsTitle {
    margin: $ticket-choose-seats-title-margin;
}

.spinnerWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10rem;
}

.row {
    clear: both;
    padding-top: 2.5rem;

    > h1 {
        margin: 0;
    }

    &:first-of-type {
        padding-top: 0;

        @include respond-to('medium') {
            padding-top: 0;
        }

        @include respond-to('large') {
            padding-top: 0;
        }
    }

    &:last-of-type {
        @include respond-to('large') {
        }
    }
}

.confirmErrorWrap {
    clear: both;
    text-align: left;

    @include respond-to('medium') {
        display: flex;
        align-items: flex-start;
    }
}

.confirmButton {
    margin: 0;
}

.divider {
    margin: $ticket-divider-margin;
    border: $ticket-divider-border;
}

.availabilityErrorMessageWrapper {
    margin-top: 1rem;
}

.options {
    @include respond-to('medium') {
        width: 17rem;
        float: left;
        margin-right: 0.625rem;
    }

    @include respond-to('x-large') {
        width: 18.25rem;
    }

    &:not(:first-of-type) {
        margin-top: 2.5rem;

        @include respond-to('medium') {
            margin-top: 0;
        }
    }
}

.optionsMobile {
    @include respond-to('medium') {
        display: none;
    }
}

.optionsTablet {
    display: none;

    @include respond-to('medium') {
        display: block;
    }
}

.infoImage {
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='13px' height='13px' viewBox='0 0 13 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3EGroup 4 Copy 2%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-4-Copy-2'%3E%3Cg id='Group-8'%3E%3Ccircle id='Oval-Copy-7' fill='%238C98B1' cx='6.5' cy='6.5' r='6.5'%3E%3C/circle%3E%3Ctext id='i' font-family='RobotoCondensed-Bold, Roboto Condensed' font-size='9.75' font-style='condensed' font-weight='bold' fill='%23FFFFFF'%3E%3Ctspan x='5.27172852' y='10'%3Ei%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    margin: -0.2rem 0 0 0;

    @include respond-to('medium') {
        margin: 0 0.3rem;
    }

    @include respond-to('large') {
        margin: -0.2rem 0.3rem 0;
    }

    &:hover {
        cursor: pointer;
    }
}

.popoverImg {
    margin-bottom: -0.15rem;
    margin-left: 0.625rem;
    width: 1rem;
    height: 1rem;
}

.childrenAges {
    display: block;
    clear: both;
    padding-bottom: 1rem;
}

.header {
    margin: 0 0 0.5rem 0;
    color: $secondary-color;

    font-size: 1.125rem;
    font-weight: normal;
}

.eventInfo {
    margin: 0.5rem 0 0 0;
    font-size: $font-size-sm-1;
}

.location {
    display: flex;
    flex-direction: column;
    margin-top: 0.3rem;

    @include respond-to('medium') {
        display: inline;
        margin-left: 0.375rem;
    }
}

.venue,
.league {
    color: $secondary-color;
}

.bull {
    display: none;

    @include respond-to('medium') {
        display: inline;
        padding: 0 0.5rem;
        color: $secondary-color;
    }
}

.error {
    background: $background-error;
    color: $error-color;
    border: $border-width-base solid $error-color;
    border-radius: $border-radius-base;
    padding: 1rem;
}

.info {
    background: $sea;
    color: $error-color;
    border: $border-width-base solid $sea;
    border-radius: $border-radius-base;
    padding: 1rem;
}

.error-wrap {
    margin: 1.25rem 0;

    @include respond-to('medium') {
        margin: 0 0 0 1rem;
    }
}

.info-wrap {
    margin: 1.25rem 0;

    @include respond-to('medium') {
        margin: 0 0 0 1rem;
    }
}

.banner {
}

.notAvailableRoot {
    min-height: 65vh;
    text-align: center;
    max-width: 29rem;
    margin: 0 auto;
}

.notAvailableTitle {
    font-size: $font-size-lg-5;

    @include respond-to('medium') {
        font-size: $font-size-lg-7;
    }

    @include respond-to('large') {
        font-size: $font-size-lg-8;
    }
}

.notAvailableText {
    line-height: 1.5;
}

.notAvailableButton {
    clear: both;
    margin: 1.875rem 0 3.75rem;
    width: 100%;
    text-align: center;

    button {
        @include respond-to('small') {
            width: auto;
        }
    }
}

.categoryNotAvailable {
    margin-top: 1rem;
}

.ticketDeliveryText {
    color: $darkblue;
    font-size: $font-size-sm-1;
    margin-bottom: 2rem;

    @include respond-to('medium') {
        display: none;
    }
}

.finalizeHintText {
    display: none;

    @include respond-to('medium') {
        display: block;
    }
}

.ticketDeliveryTextDays {
    color: $success-color;
}
