@import '../../../app/styles/variables';

.countDownWrapper {
    padding: 1rem 0.875rem;
    border: $border-color-light;
    background: white;
    font-size: $font-size-sm-1 !important;
    display: flex;
    align-items: center;
    border-radius: 8px;

    p {
        // Overrides the default font-size coming from Body.module.scss/bodySmall
        font-size: $font-size-sm-1 !important;
    }

    svg {
        margin-right: 0.5rem;
        width: 1.1875rem;
        height: 1.1875rem;
    }

    @include respond-to-reverse('x-large') {
        font-size: $font-size-xs-1;

        svg {
            width: 1rem;
            height: 1rem;
            margin-top: 1px;
        }
    }

    @include respond-to-reverse('large') {
        font-size: $font-size-sm-1;
    }

    @include respond-to-reverse('medium') {
        width: 100%;
    }
}

.countdownWrapperInline {
    padding: 0;
}

.durationText {
    color: $darkblue;
    font-weight: 700;
    margin-left: 0.3rem;
    display: inline-block;
    font-size: $font-size-sm-1 !important;
}
