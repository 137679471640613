@import '../../../app/styles/variables';

.select {
    border: $input-border-size solid $input-border-color;
    height: 50px;
    width: 100%;
    border-radius: $input-border-radius;
    overflow: hidden;
    background: $alternate-color;
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='13px' height='7px' viewBox='0 0 13 7' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com --%3E%3Ctitle%3EPath Copy 5%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M5.20710678,3.5 L9.73223305,8.33311747 C10.0892557,8.71443991 10.0892557,9.33268573 9.73223305,9.71400817 C9.37521044,10.0953306 8.79636243,10.0953306 8.43933983,9.71400817 L3.26776695,4.19044535 C2.91074435,3.80912291 2.91074435,3.19087709 3.26776695,2.80955465 L8.43933983,-2.71400817 C8.79636243,-3.09533061 9.37521044,-3.09533061 9.73223305,-2.71400817 C10.0892557,-2.33268573 10.0892557,-1.71443991 9.73223305,-1.33311747 L5.20710678,3.5 Z' id='Path-Copy-5' fill='#{encodecolor($form-select-arrow-color)}' transform='translate(6.500000, 3.500000) rotate(-450.000000) translate(-6.500000, -3.500000) '%3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: center;
    box-shadow: $input-box-shadow;

    select {
        padding: 0.75rem 0.5rem;
        width: 100%;
        height: 100%;
        border: none;
        box-shadow: none;
        background: transparent;
        background-image: none;
        -webkit-appearance: none;
        font-size: $font-size-base;
        line-height: $line-height-base;
        color: $darkblue;

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 #000;
        }
    }

    &:not(:first-of-type) {
        margin-top: 0.625rem;
    }
}

.selectSmall {
    height: 50px;

    select {
        padding: 0.5rem 0.25rem;
    }
}

.wide {
    width: 100%;
}

.select select:focus {
    outline: none;
}

.onlyIcon {
    width: 14px;
    overflow: hidden;
    border: none;
    padding: 0;
    height: 2.3rem;
    box-sizing: border-box;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    background-position: 100% 50%;

    &.select:not(:first-of-type) {
        margin: 0;
    }

    select {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding: 0;
        opacity: 0;
    }
}

.invalid {
    border-color: $error-color !important;
    border-width: $border-valid-width;

    fieldset {
        border-color: $error-color !important;
    }
}

.valid {
    border-color: $success-color;
    border-width: $border-valid-width;
}

.open {
    fieldset {
        border-color: $blue !important;
    }
}
