/*
    media mixin
    -----------

    usage:
        @include media(500px) {
            // css for above 500px
        }

        @include media(500px, 600px) {
            // css for between 500px - 599px
        }

------------------------------------------ */

@mixin respond-to($breakpoint, $landscape: false, $portrait: false) {
    $query-width: map-get($breakpoints, $breakpoint);

    @if $query-width {
        $query: unquote('(min-width: #{$query-width})');

        @if $landscape {
            $query: unquote('#{$query} and (orientation: landscape)');
        }
        @if $portrait {
            $query: unquote('#{$query} and (orientation: portrait)');
        }

        @media #{$query} {
            @content;
        }
    } @else {
        @error 'No value found for `#{$breakpoint}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin respond-to-reverse($breakpoint, $landscape: false, $portrait: false) {
    $query-width: map-get($breakpoints, $breakpoint);

    @if $query-width {
        $query: unquote('(max-width: #{$query-width})');

        @if $landscape {
            $query: unquote('#{$query} and (orientation: landscape)');
        }
        @if $portrait {
            $query: unquote('#{$query} and (orientation: portrait)');
        }

        @media #{$query} {
            @content;
        }
    } @else {
        @error 'No value found for `#{$breakpoint}`. '
        + 'Please make sure it is defined in `$breakpoints` map.';
    }
}

@mixin media($from, $to: null) {
    $query: 'screen';

    @if $from {
        $query: unquote('#{$query} and (min-width: #{$from})');
    }

    @if $to {
        $query: unquote('#{$query} and (max-width: #{$to - 1px})');
    }

    @media #{$query} {
        @content;
    }
}

/*
    clearfix mixin
    --------------

    Clearfix hack for floats
------------------------------------------ */
@mixin clearfix($pseudo-element: 'before') {
    &::after {
        content: '';
        display: table;
        clear: both;
    }
}

@function encodecolor($string) {
    @if type-of($string) == 'color' {
        $hex: str-slice(ie-hex-str($string), 4);
        $string: unquote('#{$hex}');
    }
    $string: '%23' + $string;
    @return $string;
}
