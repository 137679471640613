@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.backLinkWrapper {
    display: flex;
    align-items: center;

    & a,
    a:visited,
    a:hover,
    a:active {
        color: $blue;
        font-weight: bold;
    }

    & svg {
        & g > path {
            fill: $blue;
            stroke: $blue;
        }
        margin-right: 10px;
    }
}
