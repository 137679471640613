@import 'mixins';
@import 'breakpoints';

// theme
$darkgrey: #c8d3de;
$grey: #f5f6f7;
$mediumgrey: #f2f3f5;
$lightgrey: rgba(200, 211, 222, 0.7);
$darkGrey: rgba(148, 157, 167, 0.7);
$white: #ffffff;
$black: #000000;
$lightblack: #222222;

$sea: #1fa4f2;
$blue: #1fa4f2;
$blue-light: #1fa4f2;
$stone: #a0aabf;
$light-stone: #c8ced9;
$light-blue: #c8ced9;
$line: #c8ced9;
$darkblue: #1a1b33;
$opacityDarkBlue: rgba(37, 39, 62, 0.4);

$red: #d60000;
$light-flamingo: #ffeaea;

$green: #00af87;
// $light-green-2: #e1f0df;
$light-green: #dceedd;
$orange: #ed6e28;

// color map
$primary-color: $orange;
$secondary-color: $stone;
$tertiary-color: $green;
$info-color: #e0f3ff;
$disabled-color: $line;
$alternate-color: $white;
$error-color: $red;
$success-color: $green;
$success-color-light: #00af8714;
$warning-color: #f9ae00;
$warning-color-light: #f9ae0014;
$valid-color: $green;
$dark-color: $black;
$dark-light-color: $darkgrey;

// background-color
$background-primary: #f7f9fc;
$background-secondary: $white;
$background-tertiary: $light-blue;
$background-alternate: $black;
$background-error: $light-flamingo;
$background-valid: #00af8714;
$background-dark: #21223f;
$background-light: #fff;
$background-dark-secondary: $lightblack;

$foreground-light: #fff;
$foreground-light-accent: #1fa4f2;

$border-color-light: #eceff4;
$border-valid-width: 1px;

// Typography
// Font, line-height, and color for body text.
$font-family-base: 'DM Sans';
$text-primary-color: #1a1b33;
$text-light-color: #a0aabf;
$text-alternate-color: #fff;
$link-color: $foreground-light-accent;
$link-color-darker: #198acc;

// font size
$font-size-xs-1: 0.7rem;

// $font-size-base effects the font size of the body text
$font-size-base: 1rem;

$font-size-sm-1: 0.875rem;
$font-size-sm-2: 0.75rem;
$font-size-sm-3: 0.625rem;

$font-size-lg-1: 1.063rem;
$font-size-lg-2: 1.125rem;
$font-size-lg-3: 1.375rem;
$font-size-lg-4: 1.688rem;
$font-size-lg-5: 1.75rem;
$font-size-lg-6: 1.875rem;
$font-size-lg-7: 2.5rem;
$font-size-lg-8: 3.125rem;

// font weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

// Elements
$h1-font-family: $font-family-base;
$h1-font-size: 2.25rem;
$h1-font-size-mobile: 1.5rem;
$h1-font-weight: $font-weight-medium;
$h1-line-height: 42px;

$h2-font-family: $font-family-base;
$h2-font-size: 1.25rem;
$h2-font-size-mobile: 1.125rem;
$h2-font-weight: $font-weight-bold;
$h2-line-height: 22px;

$h3-font-family: $font-family-base;
$h3-font-size: 1rem;
$h3-font-weight: $font-weight-bold;
$h3-line-height: 22px;

$h4-font-family: $font-family-base;
$h4-font-size: 1rem;
$h4-font-weight: $font-weight-medium;
$h4-line-height: 22px;

$body-font-family: $font-family-base;
$body-font-size: 1rem;
$body-font-weight: $font-weight-normal;
$body-line-height: 1.125rem;

$body-small-font-family: $font-family-base;
$body-small-font-size: 0.875rem;
$body-small-font-weight: $font-weight-normal;
$body-small-line-height: 1.125rem;

$button-font-family: $font-family-base;
$button-font-size: 13px;
$button-font-weight: $font-weight-bold;
$button-line-height: 1.125rem;

// line height
$line-height-base: 22px;
$line-height-md: 18px;
$line-height-sm: 14px;

// border
$border-width-base: 1px;
$border-width-lg: 2px;

$border-color-base: #eceff4;

// border radius
$border-radius-base: 4px;
$border-radius-sm: 0.2rem;
$border-radius-lg: 0.3rem;

$layout-spacing-xl: 3.25rem;

// button
$btn-primary-background-color: $primary-color;
$btn-primary-background-color-hover: #d56121;
$btn-primary-text-color: $alternate-color;
$btn-primary-font-family: $font-family-base;
$btn-primary-font-size: $font-size-sm-1;
$btn-primary-font-weight: $font-weight-bold;
$btn-primary-border-radius: $border-radius-base;

// button disabled
$btn-disabled-background-color: $disabled-color;
$btn-disabled-color: $secondary-color;

// button link
$btn-default-background-color: $alternate-color;
$btn-default-text-color: $info-color;

// label
$label-primary-color: $secondary-color;
$label-secondary-color: $alternate-color;
$label-primary-border-color: $border-color-base;
$label-primary-font-size: $font-size-sm-3;
$label-primary-background-color: $primary-color;

// card
$card-background: #fff;
$card-background-inverted: $background-dark;
$card-border-color: $border-color-light;
$card-border: 1px solid $card-border-color;
$card-border-radius: 8px;
$card-box-shadow: none;
$card-body-padding: 1.5rem;
$card-body-small-padding: 1rem;

// form elements
$input-border-color: #76809a;
$input-border-size: 1px;
$input-border-radius: 4px;
$input-box-shadow: none;
$input-label-font-size: $font-size-base;
$input-label-font-weight: 500;

// FormSelect
$form-select-arrow-color: $text-primary-color;

// FormCheckbox
$form-checkbox-checked-color: $blue;
$form-checkbox-checked-background: $white;
$form-checkbox-checked-secondary-color: $blue;
$form-checkbox-checked-secondary-background: $white;
$form-checkbox-border-radius: 4px;
$form-checkbox-border-color: #a0aabf;
$form-checkbox-border-size: $input-border-size;
$form-checkbox-padding: 1.5rem;
$form-checkbox-title-color: $text-primary-color;
$form-checkbox-title-weight: 500;
$form-checkbox-subtitle-color: $text-light-color;
$form-checkbox-subtitle-weight: 400;
$form-checkbox-space-between-lines: 0.5rem;

// Modal
$modal-border-radius: 4px;

// Price
$price-primary-color: $blue;
$price-alternate-color: $green;
$price-font-weight: 700;
$price-font-size: $font-size-lg-3;
$price-font-size-big: $font-size-lg-3;
$price-prefix-color: $blue;
$price-suffix-color: $blue;

// Ticket
$ticket-divider-margin: 4rem 0 0 0;
$ticket-divider-border: 0;

$ticket-event-name-title-size: 2.2rem;
$ticket-choose-seats-title-margin: 0;

$whatsapp-link-color: $link-color;

// StepIndicator
$step-indicator-text-color: $text-primary-color;
$step-indicator-customer-service-title-weight: 500;

// Hotel List
$hotel-list-box-shadow: $card-box-shadow;

// HotelItem
$hotel-item-checked-color: $form-checkbox-checked-secondary-color;
$hotel-item-checked-background: $form-checkbox-checked-secondary-background;
$hotel-item-recommended-background: $hotel-item-checked-color;
$hotel-item-recommended-color: $hotel-item-checked-background;
$hotel-item-padding: 1rem;

// WhyPartner
$why-equipo-space-between-items: 0.7rem;

// FilterSelect FormListOption
$filter-select-option-selected-color: $primary-color;
$filter-select-option-selected-secondary-color: $form-checkbox-checked-secondary-color;

$usp-icon-color: $green;

$faq-item-font-size: 1rem;
$faq-item-color: $text-primary-color;

$header-height: 130px;
$footer-height: 100px;

// Modal
$modal-box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
