@import '../../../app/styles/variables';

.noMarginTop {
    margin-top: 0;
}

.noMarginBottom {
    margin-bottom: 0;
}

.body {
    font-family: $body-font-family;
    font-weight: $body-font-weight;
    line-height: $body-line-height;

    font-size: $font-size-sm-1;

    & svg {
        font-size: $font-size-sm-1;
    }

    @include respond-to('medium') {
        font-size: $font-size-base;
        & svg {
            font-size: $font-size-base;
        }
    }
}

.bodySmall {
    font-family: $body-small-font-family;
    font-weight: $body-small-font-weight;
    line-height: $body-small-line-height;

    font-size: $font-size-sm-1;

    & svg {
        height: $body-font-size;
    }

    @include respond-to('medium') {
        font-size: $font-size-base;
        line-height: $body-line-height;
        & svg {
            font-size: $font-size-base;
        }
    }
}

.greyText {
    color: $stone;
}

.alignLeft {
    text-align: left;
}

.alignCenter {
    text-align: center;
}

.alignRight {
    text-align: right;
}

.fontWeightMedium {
    font-weight: $font-weight-medium;
}

.fontWeightBold {
    font-weight: $font-weight-bold;
}
