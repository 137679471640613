@import '../../../app/styles/variables';

.container {
    bottom: 0;
    width: 100%;
    background-color: $background-secondary;
    border-top: 1px solid $dark-light-color;
    flex-shrink: 0;
    margin-top: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.base {
    width: 100%;
    max-width: $maxWidth;
    height: 100%;
    align-items: center;
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;

    @include respond-to-reverse('x-large') {
        padding-left: 1.875rem;
        padding-right: 1.875rem;
    }

    @include respond-to-reverse('large') {
        padding-left: 0.625rem;
        padding-right: 0.625rem;
    }

    @include respond-to-reverse('medium') {
        padding: 1rem;
        flex-direction: column;
    }
}

.baseAlignRight {
    justify-content: flex-end;
}

.paySafeWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    grid-row-gap: 1rem;

    @include respond-to-reverse('large') {
        flex-direction: column;
    }
}

.wrap {
    & svg,
    img {
        width: 36px;
        height: auto;
    }
}

.paySafe {
    display: none;
    flex: 1;
    margin: 0;
    color: $secondary-color;

    font-size: $font-size-lg-2;

    & p {
        text-align: center;
    }

    @include respond-to('large') {
        display: block;
        font-size: $font-size-lg-3;
        justify-content: flex-end;

        & p {
            text-align: left;
        }
    }
}

.secure {
    text-align: left;
    color: $valid-color;

    font-size: $font-size-sm-1;
    margin-right: 1rem;
    display: flex;
    align-items: center;

    & svg {
        margin-right: 0.5rem;
    }

    @include respond-to-reverse('medium') {
        margin-right: 0;
    }

    @include respond-to('large') {
        display: none;
    }
}

.left {
    width: 100%;
    margin-bottom: 2.875rem;
    display: block;

    @include respond-to('medium') {
        width: 50%;
        margin-bottom: 0;
    }

    @include respond-to('large') {
        width: auto;
    }
}

.right {
    display: block;
    width: 100%;
    text-align: center;

    @include respond-to('medium') {
        width: 50%;
        padding-left: 1.25rem;
    }

    @include respond-to('large') {
        display: flex;
        width: auto;
        padding-left: 0;
        margin-right: 1rem;
    }
}

.logos {
    display: block;
    padding-top: 1.25rem;

    @include respond-to('medium') {
        padding-top: 1.5rem;
    }

    @include respond-to('large') {
        margin-top: 0;
        padding-top: 0;
        display: inline;
    }
}

.right > .logos {
    margin-top: 1.75rem;

    @include respond-to('large') {
        margin-top: 0;
    }
}

.logos img {
    height: 1.125rem;
    margin-top: -0.3125rem;
    margin-right: 0.75rem;
    vertical-align: middle;
}

.left img {
    height: 2rem;
    margin-right: 1.5rem;

    @include respond-to('medium') {
        margin-right: 2rem;
    }
}

.logos img:last-child {
    margin-right: 0;
}

.divider {
    border-left: 1px solid $dark-light-color;
    height: 2rem;
    margin-right: 1.5rem;
    vertical-align: middle;
    display: inline-block;

    @include respond-to('medium') {
        height: 3rem;
        margin-right: 2rem;
    }
}

.columnTitle {
    margin-right: 1rem;

    @include respond-to-reverse('medium') {
        width: 100%;
        margin-top: 0;
        margin-bottom: 1rem;
        margin-right: 0;
    }
}

.thrustPilotWrapper {
    @include respond-to-reverse('medium') {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 1rem;
    }
}

.helpContainer {
    width: 100%;
    padding: 2rem 0;
    justify-content: center;
    display: flex;
    border-bottom: 1px solid #eff0f3;

    @include respond-to('large') {
        display: none;
    }
}

.helpText {
    display: flex;
    flex-wrap: wrap;
    font-size: $font-size-base;

    & span {
        margin-right: 5px;
    }
}
