@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties.scss';

.confirmBlocker {
    p {
        font-family: inherit;
    }
}

.button {
    height: 42px;
    @include respond-to('small') {
        height: 46px;
    }
}

.confirmButton {
    text-align: center;
    text-decoration: underline;
    cursor: pointer;
    font-size: 14px;
    color: $dark-color;
    height: 34px;
    &:link,
    &:visited {
        color: $dark-color;
    }
    @include respond-to('small') {
        height: 38px;
    }
    &:hover {
        opacity: 0.6;
    }
}
