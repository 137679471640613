@import '../../../app/styles/variables';

.root {
    display: inline-flex;
    position: relative;
    align-items: center;

    // nest to overwrite defaults
    .input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
        display: block;
    }
}

.root + .root {
    margin-left: 1.75rem;
}

.svg {
    width: 19px;
    height: 19px;
    margin-right: 0.375rem;
    display: block;

    .inner {
        display: none;
        fill: $blue;
    }

    .outer {
        stroke: $light-stone;
    }
}

@keyframes scaleIn {
    0% {
        transform: scale(0);
    }
    75% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

.input:checked ~ .svg {
    .inner {
        display: block;
        animation: scaleIn 0.2s ease-in-out;
        transform-origin: 50% 50%;
    }

    .outer {
        stroke: $blue;
    }
}

.label {
    position: relative;
    top: 1px;
    line-height: 1;
}
