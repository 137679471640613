// helpers
@import '../../../node_modules/normalize.css/normalize.css';
@import 'mixins';

// foundation
@import 'foundation/base';

// variables
@import 'breakpoints';
@import 'variables';

@import 'custom-properties';

// fonts
@import 'fonts';

* {
    outline: none;
    font-family: 'DM Sans', sans-serif;
}

html {
    overflow-x: hidden;
    font-family: $font-family-base;
}

body {
    @include var-prop('background-color', '--tco-background-primary', $background-primary);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    @include var-prop('color', '--tco-body-color', $text-primary-color);
    letter-spacing: normal;
    height: 100%;
    font-family: $font-family-base;
    min-height: 100%;
    overflow-x: hidden;
    font-size: $font-size-sm-1;
    @include respond-to('medium') {
        font-size: $font-size-base;
    }
}

pre {
    margin-top: 0;
    margin-bottom: 0;
    font-family: $font-family-base;
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-size: $font-size-sm-1;
    @include respond-to('medium') {
        font-size: $font-size-base;
    }
}
.modal-open {
    overflow: hidden;
}

html,
body {
    height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
button {
    font-family: $font-family-base;
}

h2 {
    font-size: 1rem;
    font-weight: 700;
}

a,
a:hover,
a:visited,
a:active {
    color: $blue;
}

.step-box-title {
    margin: 0;
    font-size: 1.3rem;

    font-weight: 700;

    @include respond-to('medium') {
        font-size: 1.5rem;
    }
}

.clearfix:after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: ' ';
    clear: both;
    height: 0;
}
* html .clearfix {
    zoom: 1;
} /* IE6 */
*:first-child + html .clearfix {
    zoom: 1;
} /* IE7 */

.fullWidthCard {
    @include respond-to-reverse('large') {
        margin-left: -1rem;
        margin-right: -1rem;
    }
}

// HotelDetails - Thumbnail swiper classes
.thumbsSwiper {
    padding: 0 1rem;

    & .swiper-slide:hover {
        cursor: pointer;
    }

    & .swiper-slide-thumb-active > img {
        opacity: 1 !important;
    }

    & .swiper-slide img {
        opacity: 0.4;
    }
}

// info image
.infoImage {
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='13px' height='13px' viewBox='0 0 13 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3EGroup 4 Copy 2%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='even'%3E%3Cg id='Group-4-Copy-2'%3E%3Cg id='Group-8'%3E%3Ccircle id='Oval-Copy-7' fill='%238C98B1' cx='6.5' cy='6.5' r='6.5'%3E%3C/circle%3E%3Ctext id='i' font-family='RobotoCondensed-Bold, Roboto Condensed' font-size='9.75' font-style='condensed' font-weight='bold' fill='%23FFFFFF'%3E%3Ctspan x='5.27172852' y='10'%3Ei%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.3rem;
}

.buttonHelperUl {
    padding-left: 1rem;

    & li:not(:first-of-type) {
        margin-top: 6px;
    }
}

.fullWidth {
    width: 100%;
}

.adyen-checkout__applepay__button {
    appearance: -apple-pay-button !important;
    -webkit-appearance: -apple-pay-button !important;
}

// International Telephone Input Styles
// intl-tel-input package
.iti {
    &__tel-input {
        width: 100%;
        height: 50px;
        padding: 0 12px;
        margin-right: 0.625rem;
        border: 1px solid $input-border-color;
        border-radius: $border-radius-base;
        overflow: hidden;
        display: block;
    }

    &__selected-dial-code {
        height: 2.625rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
