@import '../../../app/styles/variables';

.pickPayment {
    display: flex;
    flex-direction: column;

    @include respond-to('medium') {
        & > div {
            width: 20rem;
        }
    }

    label > img {
        margin-right: 1.5rem;
    }

    label > p {
        white-space: nowrap;
    }
}
