@import '../../../app/styles/variables';

.price {
    display: inline-flex;
    align-items: baseline;
    top: 1.5rem;
    font-size: $font-size-base;
    line-height: $line-height-base;
    margin: 0 0.2rem;
    font-weight: 500;
    padding: 0;
    color: $darkblue;
    text-align: right;
    flex-shrink: 0;
    .small {
        font-size: $font-size-sm-2;
        line-height: $line-height-md;
        color: $darkblue;
    }
}

.boldPrice {
    font-weight: 700;
}

.pricePerNight {
    text-align: left;
    margin-left: 5px;
}

.priceBig {
    font-size: $price-font-size-big;
}

.priceNumberAlternate {
    color: $price-alternate-color;
}

.priceNumberPrimary {
    color: $darkblue;
}
