@import '../../../app/styles/variables';

.wrap {
    @include respond-to('large') {
        display: none;
    }
}

.header {
    margin-top: 9px;
    margin-right: 3rem;
}

.name-break {
    @include respond-to('medium') {
        display: none;
    }
}

.name-split {
    display: none;
    @include respond-to('medium') {
        display: inline;
    }
}

.background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: $background-alternate;
    opacity: 0.5;
    z-index: 20;
}

.roomLayout {
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: scroll;
    top: 0;
    left: 0;
    margin: auto;
    background-color: $background-secondary;
    padding: 1.25rem;
    z-index: 21;

    @include respond-to('medium') {
        padding: 2.25rem 1.875rem 2.125rem 1.865rem;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: $border-radius-base;
    }
}

.topRow {
    @include respond-to('medium') {
        display: flex;
        justify-content: space-between;
    }
}

.block {
    @include respond-to('medium') {
        width: 50%;
        margin-bottom: 0;
    }

    &:first-of-type {
        padding-right: 0;
        margin-right: 0;
        @include respond-to('medium') {
            padding-right: 1rem;
            margin-right: 1rem;
            border-right: 1px solid rgba($light-stone, 0.7);
        }
    }

    &:last-of-type {
        @include respond-to-reverse('medium') {
            border-top: 1px solid rgba($light-stone, 0.7);
            margin-top: 1rem;
        }
    }
}

.features {
    display: flex;
    justify-content: space-between;
}

.features > div {
    width: 48%;
}

.hotelImage {
    width: 100%;
    margin-bottom: 1rem;
}

.pictures {
    display: inline-block;
}

.detailText {
    margin: 3rem 0 2rem 0;
}

.text {
    font-size: $font-size-base;
    line-height: $line-height-base;
    float: none;
}

.optionTitle {
    color: $secondary-color;
    font-size: $font-size-lg-2;
}

.randomImages {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
}

.randomImages > div {
    width: 48%;
    margin: 0;
    padding-bottom: 1rem;
}

.randomImages > div > img {
    padding: 0;
    margin: 0;
    width: 100%;
}

.options {
    list-style-type: none;
    margin-bottom: 0;
    padding: 0 0 15px 0;
    border-bottom: 1px solid rgba($light-stone, 0.7);

    @include respond-to('medium') {
        margin: 0;
    }
}

.options > li {
    display: flex;
    padding-bottom: 0.875rem;
}

.options > li > p:first-child {
    color: $darkblue;
    font-weight: 500;
    flex-shrink: 0;
}

.options > li:first-child {
    padding-top: 0.875rem;
}

.options > li:last-child {
    padding-bottom: 0;
}

.options > li > p {
    width: 25%;
    font-size: $font-size-sm-1;

    @include respond-to-reverse('large') {
        width: 40%;
    }
}

.options > li > p:last-child {
    width: 60%;
}

.features ul {
    list-style-type: none;
    padding: 0;
}

.features li {
    vertical-align: middle;
    font-size: $font-size-sm-1;
    line-height: $line-height-sm;
}

.blackText {
    color: $dark-color;
}

.detailsButton {
    padding: 0;
    color: $blue;
}

.matchDate {
    margin-right: 0.5rem;
}
