@import '../../../app/styles/variables';

.container {
    background-color: white;
    color: $secondary-color;
    padding: 1.25rem;
    width: 100%;
    border-radius: 8px;

    font-weight: 600;

    @include respond-to('medium') {
        padding: 1.875rem 1.875rem;
    }

    @include respond-to-reverse('large') {
        width: calc(100% + 20px);
        margin-left: -10px;
    }
}
