*,
*::before,
*::after {
    box-sizing: border-box;
}
button {
    appearance: none;
    background-color: transparent;
    border-width: 0;
}
a {
    text-decoration: none;
}
