@import '../../../app/styles/variables';

.infoNotification {
    color: $info-color;
}

.errorNotification {
    color: $error-color;
}

.successNotification {
    color: $success-color;
}
