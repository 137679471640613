.title {
    margin: 0;
}

.detailsTable {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    th,
    td {
        text-align: left;
    }

    th {
        padding-right: 1rem;
    }
}

.button {
    margin: 0;
    margin-top: 1rem;
}

.countdownWrapper {
    margin-bottom: 1rem;
}

.bookOtherEvent {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
}

.button {
    margin: 2rem auto auto auto;
}
