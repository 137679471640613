@import '../../app/styles/variables';
@import '../../app/styles/custom-properties';

.navigation {
    position: absolute;
    top: 50%;
    margin-top: -7px;
    z-index: 99;

    &:hover {
        cursor: pointer;
    }
}

.navigationPrev {
    left: 15px;
    transform: rotate(180deg);
}

.navigationNext {
    right: 15px
}

.distanceBreakfastWrapper {
    display: flex;

    & div {
        flex: 1;
    }
}

.slideImageStyle {
    width: 100%;
    height: 100%;
}

.slideImageFitContain {
    object-fit: contain
}

.slideImageFitCover {
    object-fit: cover
}

.topImages {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 1rem;
    height: 100%;
}

.imageLoading {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to-reverse('medium') {
        height: 300px;
    }

    & span > svg {
        @include var-prop('color', '--tco-background-dark', $background-dark);
    }
}
