@import 'variables';

:root {
    --tco-background-primary: #{$background-primary};
    --tco-background-dark: #{$background-dark};
    --tco-background-light: #{$background-light};
    --tco-border-color: #{$border-color-base};
    --tco-foreground-light: #{$foreground-light};
    --tco-foreground-light-accent: #{$foreground-light-accent};
    --tco-btn-primary-background: #{$btn-primary-background-color};
    --tco-btn-primary-background-hover: #{#d56121};
    --tco-card-title: #{$foreground-light-accent};
    --tco-body-color: #{$text-primary-color};
    --tco-font-primary: #{$font-family-base};
    --tco-font-secondary: #{$font-family-base};
    --tco-form-checked-success-color: #{$form-checkbox-checked-color};
}

/**
 * This mixin will let you use the variables while also providing a fallback value for when
 * either the variable doesn't exist or the browser does not support custom properties
 *
 * Usage examples:
 * @include var-prop('background-color', '--tco-background-primary', $background-primary);
 * @include var-prop('color', '--tco-body-text', #000);
 */
@mixin var-prop($property, $variable, $fallbackValue, $extraValues: '') {
    #{$property}: $fallbackValue;
    @if $extraValues != '' {
        #{$property}: $extraValues var(#{$variable}, $fallbackValue);
    } @else {
        #{$property}: var(#{$variable}, $fallbackValue);
    }
}
