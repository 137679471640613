@import '../../../app/styles/variables';

.main {
    height: 100%;
    position: relative;
}

.content {
    max-width: $maxWidth;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;

    @include respond-to('large') {
        flex-wrap: nowrap;
        margin: 0 auto;
        align-items: flex-start;
        padding-top: 1.5rem;
    }
}

.orderProgressIsOpen .content {
    margin: 0 auto 0 auto;
}

.overlay {
    height: 100%;
    position: relative;

    &.isLoading {
        overflow: hidden;
    }
}

.left {
    width: 100%;
    padding: 1rem;

    @include respond-to('medium') {
        margin: 1.5rem 1rem;
        padding: 0;
    }

    @include respond-to('large') {
        margin: 0 .75rem;
        min-height: 200px;
    }

    @include respond-to('x-large') {
        margin: 0;
    }
}

.right {
    float: left;
    margin: 0 0.625rem;
    width: 25rem;
    display: none;
    flex-shrink: 0;

    @include respond-to('large') {
        position: sticky;
        top: 9rem;
        display: block;
        margin: 0 .75rem;
    }

    @include respond-to('x-large') {
        margin-right: 0;
        margin-left: 1.5rem;
        margin-top: 0;
    }
}

.promotionBox {
    background-color: $valid-color;
    color: #fff;
    font-weight: 700;
    padding: 1rem;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: $card-border-radius;
    line-height: 1.3rem;

    a,
    a:link,
    a:visited,
    a:hover {
        color: #fff;
        text-decoration: underline;
        white-space: nowrap;
        font-size: $font-size-xs-1;
    }
}

.vspacer {
    padding-top: 8px;
    padding-bottom: 8px;

    @include respond-to('medium') {
        padding-top: 12px;
        padding-bottom: 12px;
    }

}

.vspacerSizeSmall {
    padding-top: calc($layout-spacing-xl / 4);
    padding-bottom: calc($layout-spacing-xl / 4);
}

.vspacerSizeLarge {
    padding-top: $layout-spacing-xl;
    padding-bottom: $layout-spacing-xl;
}



.dividerLeft {
    margin: 3rem 0;
    border-left: 1px solid $dark-light-color;
}

.infoImage {
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='13px' height='13px' viewBox='0 0 13 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3EGroup 4 Copy 2%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group-4-Copy-2'%3E%3Cg id='Group-8'%3E%3Ccircle id='Oval-Copy-7' fill='%238C98B1' cx='6.5' cy='6.5' r='6.5'%3E%3C/circle%3E%3Ctext id='i' font-family='RobotoCondensed-Bold, Roboto Condensed' font-size='9.75' font-style='condensed' font-weight='bold' fill='%23FFFFFF'%3E%3Ctspan x='5.27172852' y='10'%3Ei%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    margin: -0.2rem 0 0 0;

    @include respond-to('large') {
        margin: -0.2rem 0.3rem 0;
    }
}

.countdownPageWrapper {
    display: block;
    padding: 1rem 1rem 0 1rem;
    width: 100%;

    @include respond-to('large') {
        display: none;
        padding: 1.5rem 1.5rem 0 1.5rem;
    }

    @include respond-to('medium') {
        width: max-content;
        padding: 1.5rem 1rem 0 1rem;
    }
}

.eventTitleNotConfirmed {
    @include respond-to-reverse('medium') {
        margin-top: 1rem;
    }
}

.orderProgress {
    position: sticky;
    bottom: 0;
    display: inline-block;
    width: 100%;
    z-index: 2;

    @include respond-to('large') {
        display: none;
    }
}
