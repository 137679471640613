@import '../../../app/styles/variables';

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.actionCode {
    width: 100%;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(200, 211, 222, 0.7);

    > * {
        margin-right: 1rem;
        margin-top: 0rem;
        vertical-align: middle;
        line-height: 40px;
        display: inline-block;
    }
}

.text {
    font-size: $font-size-base;
    cursor: pointer;
}

.payment,
.wrong-code {
    animation: fadeOut 2s ease 5s forwards;
}

.wrong-code {
    display: block;
    margin-top: 1rem;
    padding-left: 0.625rem;
    color: $error-color;
    font-size: $font-size-base;
}

.paymentAdd {
    background-color: $info-color;
    color: $alternate-color;
    border-radius: $border-radius-base;
    padding: 0.75rem 1rem;
    font-size: $font-size-sm-1;
    line-height: $line-height-sm;
    font-weight: bold;
    vertical-align: middle;
    cursor: pointer;
    float: right;
}

.code {
    text-align: left;
    padding: 0 0.625rem;
    margin-bottom: -0.75rem;
    margin-top: 0.5rem;
    color: $info-color;
    font-size: $font-size-base;
}

.hidden {
    display: none;
}

.codeBlock,
.payment {
    display: flex;
    padding-left: 0.625rem;
    padding-top: 1.25rem;
}

.inputHidden {
    display: none;
}
