@import '../../../app/styles/variables';

.alertWrapper {
    padding: 12px 15px;
    background: #f7f7f7;
    color: #1a1b33;
    display: flex;
}

.alertTitle {
    flex: 1;
    font-weight: 500;
}

.alertIcon {
    margin-right: 8px;
    display: flex;
    align-items: center;
}
