@import '../../../app/styles/variables';

.filter {
    border: $border-width-base solid $dark-light-color;
    border-radius: $border-radius-base;
    display: none;
    height: 3.125rem;
    background-color: $background-secondary;

    @include respond-to('medium') {
        display: block;
    }
}

.mobileFilter {
    @include respond-to('medium') {
        display: none;
    }
}
