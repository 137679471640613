@import '../../../app/styles/custom-properties.scss';

.addRoomButtonContainer {
    & button {
        margin-bottom: 2rem;
        padding: 0;
        @include respond-to('small') {
            margin-bottom: 0rem;
            padding: 6px 8px;
        }
        @include var-prop('color', '--tco-foreground-light-accent', $foreground-light-accent);
        border-radius: 0;
    }
}
