@import '../../../app/styles/variables';

.discountSectionWrapper {
    margin-top: 22px;
    background: #eceff4;
    padding: 21px;
    width: 100%;
    border-radius: 8px;
}

.discountSection {
    max-width: 400px;
    margin-bottom: 1rem;
}

.discountOverviewSection {
    margin-top: 30px;
}

.discountOverviewError {
    margin-top: 0.5rem;
}
