@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.successCheckMark {
    border-top: 16px solid;
    border-left: 16px solid;
    @include var-prop('border-top-color', '--tco-form-checked-success-color', $form-checkbox-checked-color);
    @include var-prop('border-left-color', '--tco-form-checked-success-color', $form-checkbox-checked-color);

}
