@import '../../styles/variables';

.assistSection {
    display: flex;
    align-items: center;
    color: $darkblue;
    font-size: 16px;
    margin: 32px 0;

    &::before,
    &::after {
        content: '';
        flex: 1;
        height: 1px;
        background-color: $light-stone;
        margin: 0 10px;
    }

    &::before {
        margin-left: 0;
    }
    &::after {
        margin-right: 0;
    }
}
