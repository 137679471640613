@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.root {
    background-color: $card-background;
    border-radius: 8px;

    @include respond-to('medium') {
        box-shadow: $card-box-shadow;

        &.variantInverted {
            @include var-prop('color', '--tco-foreground-light', $foreground-light);
            @include var-prop('background-color', '--tco-background-dark', $background-dark);
        }
    }
}

.body {
    position: relative;
    padding: $card-body-small-padding;

    @include respond-to('large') {
        padding: $card-body-padding;
    }

    &.hasBottomDivider {
        border-bottom: $card-border;
    }

    &.hasTopDivider {
        border-top: $card-border;
    }
}
