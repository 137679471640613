@import '../../../app/styles/variables';

.row {
    width: 100%;
    display: grid;
    grid-template-columns: none;
    grid-column-gap: 2rem;
    align-items: center;

    @include respond-to('medium') {
        display: flex;
    }

    > h1 {
        margin: 0;
    }

    &:first-of-type {
        padding-top: 0;

        @include respond-to('medium') {
            padding-top: 0;
        }
    }
}

.confirmErrorWrap {
    clear: both;
    text-align: left;

    @include respond-to('medium') {
        display: flex;
        justify-content: flex-start;
    }
}

.confirmButtonWrapInverted {
    justify-content: flex-start;

    @include respond-to('medium') {
        justify-content: flex-end;
    }
}

.confirmButton {
    margin: 0;
}

.errorWrap {
    margin: 1.25rem 0;

    @include respond-to('medium') {
        margin: 0 0 0 1rem;
    }
}

.infoWrap {
    margin: 1.25rem 0;

    @include respond-to('medium') {
        margin: 0 0 0 1rem;
    }
}

.textWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 8px;
    margin-bottom: 8px;

    > p {
        font-size: $font-size-sm-1 !important;
    }

    @include respond-to('medium') {
        justify-content: center;
        margin-top: 0;
    }
}

.infoText {
    color: $text-light-color;
    font-size: $font-size-sm-1;
}

.infoTextDark {
    color: black;
}

.infoTextBig {
    font-size: $font-size-base;
}

.rowInverted {
    grid-row: 1; /* NEW */
}

.confirmButtonMicroCopy {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backToPrevious {
    color: $blue;
    font-weight: 500;
    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}

.backToPreviousTablet {
    margin-top: 2rem;
}
