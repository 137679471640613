@import '../../styles/variables';

.root {
    min-height: 65vh;
    text-align: center;
    max-width: 29rem;
    margin: 0 auto;
}

.title {
    font-size: $font-size-lg-5;
    color: $darkblue;
    margin: 0;
    margin-bottom: 16px;
}

.text {
    line-height: 1.5;
    color: $darkblue;
    margin: 0;
    margin-bottom: 24px;
}

.button {
    height: 46px;
    margin-bottom: 16px;
}

.assistSection {
    display: flex;
    align-items: center;
    color: $dark-color;
    font-size: 16px;
    margin: 32px 0;

    &::before,
    &::after {
        content: '';
        flex: 1;
        height: 1px;
        background-color: $dark-color;
        margin: 0 10px;
    }
}

.buttonIcon {
    margin-left: 0.5rem;
    svg {
        fill: white;
        width: 16px;
        height: 16px;
    }
}
.backToWebsiteBtn {
    height: 46px;
    border-color: $darkblue;
    color: $darkblue;
    margin-bottom: 32px;
    background-color: transparent;
}

.buttonsContainer {
    margin: 0 auto;
    max-width: unset;
    @include respond-to('small') {
        width: auto;
        max-width: 320px;
    }
}
