@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties.scss';

.hasImage .bg {
    background: linear-gradient(to top, rgba($dark-color, 0.8) 0%, rgba($dark-color, 0) 50%);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.wrap {
    position: relative;
    width: 100%;
    padding: 0.625rem 1.875rem 0 0.625rem;
    margin-bottom: -0.625rem;

    @include respond-to('medium') {
        margin-bottom: 2rem;
        padding: 0;
    }

    &.hasImage {
        height: 13.375rem;
        box-shadow: 0.1rem 0.125rem 0.25rem 0 rgba(10, 10, 10, 0.1);
        background-size: cover;
    }
}

.wrapMobile {
    @include respond-to('medium') {
        display: none;
    }
}

.wrapRounded {
    border-radius: $border-radius-lg;
}

.label {
    position: absolute;
    top: 1.2rem;
    left: -0.3rem;
    padding: 0.5rem 0.5rem 0.5rem 0.7rem;
    border-radius: 0 $border-radius-lg $border-radius-lg 0;
    background-color: $label-primary-background-color;
    color: $label-secondary-color;
    font-size: $label-primary-font-size;

    box-shadow: 0.1rem 0.125rem 0.25rem 0 rgba($dark-color, 0.7);

    &::before {
        display: block;
        position: absolute;
        left: 0.125rem;
        bottom: -0.25rem;
        transform: rotate(45deg);
        z-index: -1;
        content: '';
        border: 0.3rem solid darken($primary-color, 20%);
    }
}

.hasNoImage .eventName {
    @include var-prop('color', '--tco-body-color', $text-primary-color);
}

.hasImage .eventName {
    color: $text-alternate-color;
    position: absolute;
    left: 2rem;
    bottom: 1.4rem;
}

.wrap--mobile .eventName {
    padding-left: 0;
    padding-top: 0.625rem;
}

.eventName {
    display: block;
    margin: 0;

    line-height: $line-height-base;
    font-weight: bold;
}
