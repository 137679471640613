@import '../../../app/styles/variables';

.wrap {
    @include respond-to('medium') {
        margin: 0 0rem 0 0;
    }
}
.back-button {
    appearance: none;
    display: block;
    width: 100%;
    padding: 1.125rem;
    color: $info-color;

    text-align: left;
    background-color: $background-secondary;
    border: $border-width-base solid $light-stone;
    border-bottom-width: 0;
    border-radius: $border-radius-base $border-radius-base 0 0;
    cursor: pointer;
}
