@import '../../../app/styles/variables';

.wrap {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 1.225rem;
    right: 1.125rem;
    padding: 0.45rem 0.45rem;
    margin: 0;
    height: 2rem;
    width: 2rem;
    border: $border-width-base solid $border-color-base;
    border-radius: 1.25rem;
    cursor: pointer;

    @include respond-to('medium') {
        right: 1.25rem;
    }
}

.cross {
    height: 80%;
    width: 80%;
}

.paths {
    fill: $blue;
}
