@import '../../styles/variables';

.container {
    position: relative;
}

.headerWrap {
    position: relative;
}

.title {
    color: $secondary-color;
}

.button {
    clear: both;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    button {
        margin: 0;
        @include respond-to('small') {
            width: auto;
        }

        @include respond-to-reverse('medium') {
            width: 100%;
        }
    }
}

.bookOther {
    margin-top: 0.25rem;
    padding: 0;

    @include respond-to('medium') {
        display: flex;
        align-items: center;
        position: absolute;
        bottom: -3.2rem;
        right: 0;
        margin-top: 0;
    }
}

.bookOtherCheckbox {
    position: relative;
    margin-right: 0.625rem;
}

.header {
    padding: 0;
}

.error {
    margin-bottom: 0.625rem;

    @include respond-to('medium') {
        margin-bottom: 1rem;
    }

    &:last-of-type {
        margin-bottom: 1.75rem;

        @include respond-to('medium') {
            margin-bottom: 2.875rem;
        }
    }
}

.back {
    padding: 0;
    margin-top: 0;
}
