@import '../../../app/styles/variables';

.contact {
    width: 100%;
    display: flex;
    vertical-align: top;
    line-height: 2;
    font-size: $font-size-sm-1;
}

.wrapper {
    position: relative;
    display: flex;
    margin-bottom: 2rem;
}

.employeeImageContainer {
    position: relative;
    display: inline-block;
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    flex-shrink: 0
}

.employeeImage {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    flex-shrink: 0;
}

.badge {
    height: 20px;
    width: 20px;
    position: absolute;
    top: -3px;
    right: -3px;
    border-radius: 50%;
    border: 3px solid white;
}

.onlineBadge {
    background-color: $success-color;
}

.offlineBadge {
    background-color: $error-color;
}

.phone {
    color: $secondary-color;

    a {
        color: $blue;
    }
}

.whatsapp {
    .online {
        color: $secondary-color;
    }
}

.phone, .whatsapp {
    font-size: $font-size-base;
}

.whatsappLink {
    &:visited, :focus, :active {
        color: $valid-color;
    }

    &::before {
        content: ' ';
        display: inline-block;
        margin: 0 0.4rem -0.125rem 0;
        height: 1rem;
        width: 1rem;
        background-image: url('/images/icon-whatsapp.svg');
        background-repeat: no-repeat;
    }
}

.supportParagraph {
    margin-top: 0;
    margin-left: 1rem;
}

.supportText {
    margin-top: 2rem;
}

.timezone {
    font-size: $font-size-sm-1;
    color: $darkGrey
}
