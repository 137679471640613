@import '../../styles/variables';

.container {
    .list {
        display: inline-block;
        width: 100%;

        @include respond-to('medium') {
            margin: 0 1rem 0 0;
            width: calc(50% - 0.625rem);
            max-width: 295px;
        }

        @include respond-to('x-large') {
            width: 21.875rem;
        }
    }

    .listFullWidth {
        margin: 0;
        width: 100%;
        max-width: none;
    }

    .hotelSelector {
        position: relative;

        @include respond-to('medium') {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
        }

        .map {
            position: sticky;
            top: 170px;
            display: none;
            float: right;
            width: 50%;

            @include respond-to('medium') {
                flex: 1;
            }

            @include respond-to('large') {
                top: 8.5rem;
            }
        }

        .mapActive {
            @include respond-to('medium') {
                display: inline-block;
            }
        }

        .legend {
            display: flex;
            align-items: center;
            float: right;
            margin: 0;
            padding: 0.625rem;
            color: $secondary-color;
            background-color: $background-secondary;
        }

        .legendIcon {
            display: inline-block;
            margin-right: 0.5rem;
            height: 1rem;
            width: 1rem;
            background-color: rgba($sea, 0.1);
            border: $border-width-lg solid rgba($sea, 0.5);
            border-radius: 1rem;
        }
    }
}

.button {
    margin: 1.875rem 0 3.75rem;
}

.buttonWithMap {
    & > button {
        width: 100%;
    }
}

.buttonNoMap {
    & > button {
        width: auto;
    }
}

.buttonLink {
    display: block;
    padding: 1rem;
    margin: 0.25rem auto;
    color: $blue;
    cursor: pointer;
    padding-right: 2rem;
}

.extraOptions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 0.625rem;

    @include respond-to('medium') {
        margin-left: 0;
    }
}

.breakfastCheckboxContainer {
    margin-block-start: 1em;
    margin-block-end: 1em;
}

.breakfastCheckbox {
    margin-right: 1rem;
}

.desktopOnly {
    display: none;

    @include respond-to('medium') {
        display: block;
    }
}

.sortHotels {
    @include respond-to('medium') {
        display: none;
    }
}

.backlinkWrapper {
    margin-bottom: 1.6rem;
}
