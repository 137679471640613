@import "../../../../../app/styles/variables";

.informationStack {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.tableHeading {
    margin-bottom: 4px;
    font-size: $font-size-sm-1;
}

.rowLabel {
    color: #76809A;
}

.informationGrid {
    display: grid;
    grid-template-columns: 124px auto;
    @include respond-to('large') {
        grid-template-columns: 124px minmax(242px, max-content) 124px minmax(242px, max-content);
    }
    grid-column-gap: 1rem;
    grid-row-gap: 4px;
    font-size: $font-size-sm-1;
}

.showOnMobile {
    @include respond-to('medium') {
        display: none;
    }
}

.showOnDesktop {
    display: none;

    @include respond-to('medium') {
        display: grid;
    }
}

.collapseButton {
    font-size: $font-size-sm-1;
    display: flex;
    align-items: center;
    gap: 2px;
    text-decoration: underline;
    padding: 0;
    color: black;

    &:hover {
        cursor: pointer;
    }
}

.chevron {
    margin-left: 2px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
        transform: rotate(180deg);
        transition: ease 0.2s transform;
        width: 10px;
        height: 10px;
    }
}

.chevronOpen {
    & svg {
        transform: rotate(0deg);
        transition: ease 0.2s transform;
    }
}

.travellersContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
