@import '../../../app/styles/variables';

.formContainer {
    display: flex;
    flex-direction: column;

    @include respond-to-reverse('medium') {
        width: 100%;
    }
}

.formFields {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    border: 1px solid #212141;
    border-radius: 1px;
    box-shadow: none;
    height: 2.5rem;
    position: relative;
}

.fieldLabel {
    display: flex;
    align-items: flex-start;
    clear: both;
    margin: 0.5rem 0;
    font-weight: $input-label-font-weight;
    font-size: $input-label-font-size;
}

.selectedOption {
    padding: 0 0.625rem;
    white-space: nowrap;
    line-height: initial;
    text-align: center;
}

.errorMessage {
    width: 100%;
    background: $background-error;
    color: $error-color;
    border-radius: $border-radius-base;
    padding: 0.375rem 0.625rem;
    margin: 0.625rem 0.625rem 0 0;
    font-size: $font-size-sm-1;

    @include respond-to('medium') {
        width: 15rem;
    }
}

.error {
    border-color: $error-color;
    border: 1px solid red !important;
}

.errorInputField input {
    border: 1px solid red !important;
}

.valid {
    border-color: $success-color;
    border-width: $border-valid-width;
}

.defaultImage {
    width: auto;
    height: 1rem;
    box-sizing: border-box;
    vertical-align: middle;
}

.selectedImage {
    vertical-align: middle;
    width: 24px;
}
