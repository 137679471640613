@import '../../../app/styles/variables';

// discount input
.discountInput {
    width: 100%;
    padding: 1.6rem 1.25rem;
}

.addDiscountInputWrapper {
    display: grid;
    grid-template-columns: 10fr 1fr;
    grid-column-gap: 0.625rem;

    @include respond-to-reverse('small') {
        display: block;
    }
}

.addDiscountButton {
    padding: 1rem;
    margin: 0;
    flex: 0;

    & img {
        height: 17px;
        width: 17px;
    }

    @include respond-to-reverse('small') {
        width: 100%;
        margin-top: 1rem;
    }
}

// Discount overview
.discountList {
    padding-top: 1rem;
}

.discountListWithBorders {
    border-top: 1px solid $lightgrey;
    border-bottom: 1px solid $lightgrey;
    padding-bottom: 1rem;
}

.discountInputLabel {
    font-weight: $font-weight-medium;
}

// Discount row
.discountRow {
    display: flex;
    clear: both;
    font-size: 0.875rem;
    align-items: center;

    &:not(:last-of-type) {
        margin-bottom: 0.4rem;
    }
}

.discountTravelPart {
    flex: 1;
    line-height: 1.5;
    display: flex;
    align-items: center;

    & span {
        display: flex;
        font-size: 15px;
        color: $darkblue;
        align-items: center;
    }

    & svg {
        margin-right: 1.3rem;
        &:hover {
            cursor: pointer;
        }
    }
}

.discountAmount {
    color: $green;
    font-size: 1rem;
}

.notificationMessage {
    margin-top: 0.5rem;
}

.greyCode {
    color: $darkGrey;
    font-size: 0.7rem;
    margin-bottom: 5px;
    padding-left: 35px;
}
