@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.resetTravellerInfo {
    color: $blue;

    & svg {
        fill: $blue;
    }
}

.discountContainer {
    border-top: 1px solid $line;
    padding-top: 1.5rem;
    margin-top: 1.5rem;
}

.consentCheckbox {
    margin-top: 1rem;
}

.consentLabel {
    font-size: 0.875rem;
}
