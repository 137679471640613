@import '../../../app/styles/variables';

.radioLabel {
    font-size: $font-size-base;

    &:first-of-type {
        margin-right: 1.75rem;
    }
}

.whiteBlock {
    @include respond-to('medium') {
        background-color: $background-secondary;
        padding: 1.875rem;
    }
}

.formSection {
    padding: 1.25rem;
    background-color: $background-secondary;
    border-radius: $border-radius-base;

    @include respond-to('medium') {
        padding: 0;
        background-color: transparent;
    }

    &:not(:first-of-type) {
        margin-top: 0.625rem;
    }
}

.multi {
    &:not(:last-child) {
        margin-bottom: 1rem;
    }

    @include respond-to('medium') {
        display: flex;
    }
}

.field {
    margin-top: 1.25rem;
}

.line {
    clear: both;
    display: none;
    border: 0;
    border-top: 1px solid $line;
    padding: 0rem 0;
    margin: 1.875rem 0;

    @include respond-to('medium') {
        display: block;
    }
}

input[type='radio'] {
    margin-left: 0.4rem;
    margin-right: 0.2rem;
    height: 0.9rem;

    &:first-of-type {
        margin-left: 0;
    }
}

.fieldLabel {
    margin: 0.5rem 0;
    font-size: $input-label-font-size;
    font-weight: $input-label-font-weight;
}

.fieldLabelDate {
    margin: 1rem 0 0;
}

.fieldLabelAddress {
    margin: 1.5rem 0 .5rem 0;
}

.header {
    margin: 2.5rem 0.625rem 1.25rem;
    font-size: $font-size-lg-3;

    @include respond-to('medium') {
        margin: 2.5rem 0 1.25rem;
    }
}

.suggestion {
    color: $blue;

    &:hover {
        cursor: pointer;
    }
}

.infoImage {
    background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8'?%3E%3Csvg width='13px' height='13px' viewBox='0 0 13 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com --%3E%3Ctitle%3EGroup 4 Copy 2%3C/title%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='even'%3E%3Cg id='Group-4-Copy-2'%3E%3Cg id='Group-8'%3E%3Ccircle id='Oval-Copy-7' fill='%238C98B1' cx='6.5' cy='6.5' r='6.5'%3E%3C/circle%3E%3Ctext id='i' font-family='RobotoCondensed-Bold, Roboto Condensed' font-size='9.75' font-style='condensed' font-weight='bold' fill='%23FFFFFF'%3E%3Ctspan x='5.27172852' y='10'%3Ei%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    width: 13px;
    height: 13px;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.3rem;
}
