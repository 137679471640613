@import '../../../app/styles/variables';

.container {
    padding: 0.625rem;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2.225rem;

    @include respond-to-reverse('large') {
        display: block;
    }
}

.description {
    line-height: $line-height-base;
    font-size: $font-size-base;
}

.title {
    font-size: 1.75rem;

    @include respond-to('medium') {
        font-size: 2.5rem;
    }
}

.whatsappLink {
    color: $valid-color;

    &::before {
        content: ' ';
        display: inline-block;
        margin: 0 0.4rem -0.125rem 0;
        height: 1rem;
        width: 1rem;
        background-image: url('/images/icon-whatsapp.svg');
        background-repeat: no-repeat;
    }
}

.phone {
    padding-right: 0.5rem;
    color: $blue;
}

.span {
    padding-right: 0.5rem;
}

.homeButton {
    margin-top: 4rem;
}

.thankYouBlock {
    flex: 2;
}

.faqBlock {
    width: 398px;
    margin-left: 1rem;

    @include respond-to-reverse('large') {
        margin-top: 3rem;
        width: 100%;
        margin-left: 0;
    }
}
