@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.formrow {
    position: relative;
    float: left;
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 2px rgba($dark-color, 0.06);
}

.spacingBottom:not(:last-of-type) {
    margin-bottom: 1rem;
}

.checklabel {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid $form-checkbox-border-color;
    background: $alternate-color;
    border-radius: $form-checkbox-border-radius;
    padding: $form-checkbox-padding;
    line-height: $line-height-sm(base);
    flex-grow: 4;
    height: 100%;
    font-weight: 700;
}

.labelWrapper {
    display: flex;
    align-items: center;

    & svg,
    img {
        width: 2.5rem;
        height: auto;
        margin-right: 1rem;
    }
}

.white {
    background: $alternate-color;
}

.checklabel > small {
    font-size: $font-size-sm-1;
    height: 100%;
}

.checklabel:hover {
    border-color: $input-border-color;
}

.checkbox {
    display: none;
}
.checkbox:focus + label {
    border-color: $info-color;
}
.checkbox:active + label {
    background-color: #ddd;
}

.checkbox:checked + label {
    @include var-prop(
        'box-shadow',
        '--tco-form-checked-success-color',
        $form-checkbox-checked-color,
        0 0 0 2px
    );
    outline: none;
    @include var-prop('color', '--tco-form-checked-success-color', $blue);
    // The border is actully the 'box-shadow' defined above.
    // Reason is for a better visual effect which includes the checkmark as well
    border-color: transparent;
}

.secondary .checkbox:checked + label {
    @include var-prop(
        'box-shadow',
        '--tco-form-checked-success-color',
        $form-checkbox-checked-color,
        0 0 0 2px
    );
    outline: none;
    @include var-prop('color', '--tco-form-checked-success-color', $form-checkbox-checked-color);
    // The border is actully the 'box-shadow' defined above.
    // Reason is for a better visual effect which includes the checkmark as well
    border-color: transparent;
}

.subTitle {
    margin-top: 0.5rem;
    flex: 1;
    font-size: 0.875rem;
}

.subtitlePopoverButton {
    position: relative;
    top: 2px;
    margin-left: 4px;
}

.price {
    text-align: right;
    margin-right: 0;
    color: $secondary-color;
}

.priceLabel {
    text-align: right;
    margin-right: 0;
    color: $stone;
}

.icon {
    display: inline-block;
    position: relative;
    width: 30px;
    left: 0.5rem;
    margin-right: 1rem;
}

.subtitlelabel {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.fullHeight {
    padding: 1.125rem 1rem 1.125rem 1.25rem;
}

.greenPriceText {
    color: $green;
    font-weight: bold;
}

.smallPriceText {
    font-size: 0.85rem !important;
}

.checkedIconContainer {
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: $border-radius-base;
}

.disabledOption:hover {
    box-shadow: 0 0 0 1px $form-checkbox-border-color;
    cursor: not-allowed;
}

.disabledLabel {
    color: $darkblue;
    opacity: 0.4;
}
