@import '../../../app/styles/variables';

.statusDot {
    border-radius: 50%;
    display: inline-block;
    width: 6px;
    height: 6px;
    background: $green;
    margin-bottom: 1px;
    margin-right: 5px;
    margin-top: 3px;
}

.statusDotOffline {
    background: $red;
}
