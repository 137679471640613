@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.modalContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: $opacityDarkBlue;

    z-index: 99;
    overflow-y: scroll;
    margin: 0;
}

.modalContainerSmallDialog {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modalBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.modalDialog {
    margin: 8rem auto;
    background: white;
    border-radius: $border-radius-base;
    box-shadow: $modal-box-shadow;
    display: flex;
    flex-direction: column;
    padding: 24px;
    position: relative;
    z-index: 11;
    width: 95%;
    color: $text-primary-color;
    max-width: 850px;
    overflow: auto;

    @include respond-to-reverse('large') {
        width: 80%;
    }

    @include respond-to-reverse('medium') {
        width: 100%;
        margin: 0;
        min-height: 100%;
    }
}

.modalDialogSmall {
    @include respond-to-reverse('large') {
        width: 60%;
    }

    @include respond-to-reverse('medium') {
        width: 90%;
        margin: 0;
        min-height: unset;
    }
}

.modalDialogSmallSize {
    width: 486px;

    @include respond-to-reverse('medium') {
        width: 100%;
    }
}

.modalCloseButton {
    & button {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
    }
}

.modalTitle {
    text-align: center;
    margin-bottom: 2.5rem;
    @include var-prop('color', '--tco-body-color', $text-primary-color);
}

.title {
    font-size: 1.2rem;
    font-weight: bold;
}

.modalBody {
    flex: 1;
    @include var-prop('color', '--tco-body-color', $text-primary-color);
}

.modalFooter {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    position: sticky;
    bottom: 0;
}

.modalFooterFlexEnd {
    justify-content: flex-end;
}

.modalCustomMobile {
    min-height: unset !important;
    height: auto !important;
    width: 90% !important;
    top: 200px !important;
    left: 50% !important;
    transform: translate(-50%, 0%) !important;
    padding: 24px !important;
}
