@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties.scss';

.button {
    padding: 1.2rem 1.875rem;
    height: 42px;
    color: $alternate-color;
    font-family: $btn-primary-font-family;
    font-weight: $btn-primary-font-weight;
    @include var-prop(
        'background-color',
        '--tco-btn-primary-background',
        $btn-primary-background-color
    );
    border-radius: $btn-primary-border-radius;
    transition-duration: 0.1s;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: $font-size-sm-1;
    line-height: $line-height-md;

    @include respond-to('medium') {
        width: auto;
        height: 50px;
        font-size: $font-size-base;
    }

    // Apply the background-hover change only to active buttons (not disabled)
    &:not([disabled]):hover {
        $darkened-primary-background-color: darken($btn-primary-background-color, 5%);
        background-color: var(
            --tco-btn-primary-background-hover,
            $darkened-primary-background-color
        );
    }

    &:active {
        transform: scale(1);
    }

    &.fullWidth {
        width: 100%;
    }
}

.buttonWhite {
    padding: 10px 0;

    font-size: $font-size-base;
    color: $btn-default-text-color;
    background-color: $btn-default-background-color;
    border: $border-width-base solid $disabled-color;

    &:not([disabled]):hover {
        @include var-prop(
            'background-color',
            '--tco-btn-primary-background-hover',
            darken($btn-primary-background-color, 3%)
        );
    }

    &:active {
        transform: scale(1);
    }
}

.buttonDisabled {
    background-color: $btn-disabled-background-color;
    color: $btn-disabled-color;
    cursor: default;
}

.buttonLeft {
    margin: 1.875rem 0 0;

    @include respond-to('medium') {
        margin: 1.875rem 0 0;
    }
}

.outlinedButton {
    background: white;
    border: 1px solid $stone;
    color: $stone;
    font-weight: 'normal';

    &:hover {
        background: $grey !important;
    }
}

.iconLeft {
    margin-right: 0.4rem;
}

.iconRight {
    margin-left: 0.4rem;
}

.spinner {
    margin-right: 0.5rem;
}

.spinnerNoMargin {
    margin: 0;
}
