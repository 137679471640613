@import '../../styles/variables';

.wrap {
    margin-bottom: 4rem;

    @include respond-to('medium') {
        margin-top: -5rem;
        margin-bottom: 0;
    }

    @include respond-to('large') {
        margin-top: 0;
    }
}
