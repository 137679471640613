@import '../../../app/styles/variables';

.multi {
    display: flex;
}

.input {
    border: $input-border-size solid $input-border-color;
    width: 4.57rem;
    height: 2.5rem;
    border-radius: $input-border-radius;
    overflow: hidden;
    background: $alternate-color;
    text-align: center;
    display: block;
    margin-right: 0.625rem;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    &:not(.isPristine) {
        border-color: $valid-color;
    }

    &.invalid {
        border-color: $error-color;
    }

    @include respond-to-reverse('medium') {
        width: 95% !important;
        margin-left: 1.25%;
        margin-right: 1.25%;
    }
}

::-webkit-input-placeholder {
    color: $secondary-color !important;
}
::-moz-placeholder {
    color: $secondary-color;
}
:-ms-input-placeholder {
    color: $secondary-color;
}
:-moz-placeholder {
    color: $secondary-color;
}

.name {
    margin-bottom: 0.5rem;
}

.errorMessage {
    background: $background-error;
    color: $error-color;
    border-radius: $border-radius-base;
    padding: 0.375rem 0.625rem;
    margin-top: 0.625rem;
    max-width: 12.125rem;
    font-size: $font-size-sm-1;
}
