@import '../../../app/styles/variables';

.container {
    background-color: #fff;
    padding: 1.25rem;
    width: 100%;
    margin-bottom: 2.5rem;
    border-radius: $border-radius-base;

    ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: none;
        @include respond-to('medium') {
            display: inline-block;
        }
    }

    li {
        float: left;
        margin-right: 2.3125rem;
    }
}

.headerWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.25rem;

    @include respond-to-reverse('medium') {
        margin-bottom: 0;
    }
}

.detail {
    margin-top: .625rem;
    text-transform: lowercase;
}
