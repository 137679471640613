@import '../../../app/styles/variables';

.wrap {
    position: relative;
    padding: 1rem;
    font-size: $font-size-base;
    background-color: $info-color;
    border-left: 0.25rem solid $info-color;
    border-radius: $border-radius-base;
    display: flex;

    &:before {
        flex-shrink: 0;
        margin-right: 1rem;
        height: 1rem;
        width: 1rem;
        background-image: url('/images/icon-info-2.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        content: '';
        color: $info-color;
        fill: $blue;
    }

    &:not(:first-of-type) {
        margin-top: 0.625rem;

        @include respond-to('medium') {
            margin-top: 1.25rem;
        }
    }

    @include respond-to('medium') {
        padding: 1rem;
    }
}

.message {
    margin: 0;
    line-height: $line-height-base;
}

.missing-fields {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    line-height: 1.5;
}

.field {
    padding-right: 1rem;
    color: $info-color;
    list-style: none;

    &::first-letter {
        text-transform: capitalize;
    }
}
