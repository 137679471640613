@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.noMarginTop {
    margin-top: 0;
}

.noMarginBottom {
    margin-bottom: 0;
}

.uppercase {
}

.grey {
    color: $stone;
}

.heading {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    & > svg {
        margin-right: 0.75rem;
    }
}

.h1 {
    @include var-prop('font-family', '--tco-font-primary', $h1-font-family);
    font-size: $h1-font-size;
    font-weight: $h1-font-weight;

    & > svg {
        height: $h1-font-size;
        width: $h1-font-size;
    }

    @include respond-to-reverse('medium') {
        font-size: $h1-font-size-mobile;

        & > svg {
            height: $h1-font-size-mobile;
            width: $h1-font-size-mobile;
        }
    }
}

.h2 {
    font-size: $h2-font-size;
    font-weight: $h2-font-weight;
    line-height: $h2-line-height;

    & > svg {
        height: $h2-font-size;
        width: $h2-font-size;
    }

    @include respond-to-reverse('medium') {
        font-size: $h2-font-size-mobile;

        & > svg {
            height: $h2-font-size-mobile;
            width: $h2-font-size-mobile;
        }
    }
}

.h3 {
    font-size: $h3-font-size;
    font-weight: $h3-font-weight;
    line-height: $h3-line-height;

    & > svg {
        height: $h3-font-size;
        width: $h3-font-size;
    }
}

.h4 {
    font-size: $h4-font-size;
    font-weight: $h4-font-weight;
    line-height: $h4-line-height;

    & > svg {
        height: $h4-font-size;
        width: $h4-font-size;
    }
}

.alignCenter {
    justify-content: center;
    text-align: center;
}

.alignRight {
    justify-content: flex-end;
    text-align: right;
}

.boldHeading {
    font-weight: bold;
}

.capitalize {
    text-transform: capitalize;
}
