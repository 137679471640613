@import '../../../app/styles/variables';

.wrap {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 40rem;
    background-color: $background-secondary;
    overflow: hidden;

    h1 {
        font-size: 24px;
        line-height: 28px;
        font-weight: 700;
    }

    @include respond-to('medium') {
        margin: 3.625rem auto 4rem;

        &:after {
            bottom: -5.5rem;
        }
    }
}

.img {
    width: 100%;
    height: auto;
}

.content {
    max-width: 27rem;
    padding: 0 1rem 3rem 1rem;
    margin: 0 auto;
    text-align: center;

    & h2 {
        font-size: $font-size-lg-3;
    }

    @include respond-to('medium') {
        padding: 0 0 3rem 0;
    }
}

.description {
    font-size: 0.9rem;
    line-height: 1.5;
}

.button {
    margin: auto;
}

.imgWrap {
    position: relative;
}

.copyright {
    position: absolute;
    top: 16px;
    right: 16px;
    font-size: $font-size-sm-1;
    color: white;
}
