@import '../../../app/styles/variables';

.container {
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}

.title {
    margin-top: 1px;
    text-decoration: underline;
}

.chevron {
    margin-left: 10px;
    margin-top: -1px;

    & svg {
        transform: rotate(180deg);
    }
}

.chevronOpen {
    & svg {
        transform: rotate(0deg);
        transition: ease 0.2s transform;
    }
}
