@import '../../../app/styles/variables';

.wrap {
    list-style: none;
    margin: 0.875rem 0;
    padding: 1rem;
    background: $background-primary;
    color: $darkblue;
    height: 100%;
    border-radius: $border-radius-base;

    &:last-of-type {
        border-bottom: 0;
        margin-bottom: 0;
    }
}
.highlight {
    background: #fff4c6;
}
.question {
    width: 100%;
    padding-left: 0;
    color: $faq-item-color;
    cursor: pointer;
    font-size: $faq-item-font-size;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.questionText {
    padding-right: 0.3rem;
}

.arrow {
    margin-right: 0.5rem;
    margin-bottom: 0.1rem;
    transition: transform 0.3s ease;
}

.arrowReverse {
    transform: rotate(180deg);
}

.answerWrap {
    padding-top: 0.6rem;
    line-height: $line-height-base;
    font-size: $font-size-sm-1;
    overflow: hidden;
}

.answer {
    padding-bottom: 1rem;
    display: block;
}

.arrowDown {
    transition: 0.3s ease transform;
    display: flex;
    align-items: center;
}

.arrowDownActive {
    transform: rotate(-180deg);
}
