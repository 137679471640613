@import '../../../app/styles/_variables.scss';

.wrap {
    @include respond-to('large') {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100vh;
        width: 100%;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch;
        z-index: 10;
    }
}

.header {
    margin: 1rem 4rem 2rem 0.625rem;
    font-size: $font-size-lg-5;

    @include respond-to('medium') {
        margin-top: 0;
        margin-left: 0;
        font-size: $font-size-lg-7;
    }

    @include respond-to('large') {
        font-size: $font-size-lg-6;
    }
}

.background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    background-color: $background-alternate;
    opacity: 0.5;

    @include respond-to('large') {
        position: absolute;
    }
}

.notificationLayout {
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    padding: 0.625rem 0.625rem 7.25rem;
    padding: 0.625rem 0.625rem calc(env(safe-area-inset-bottom, 1.625rem) + 5.625rem);
    background-color: $background-secondary;
    overflow-y: scroll;
    z-index: 10;
    -webkit-overflow-scrolling: touch;

    @include respond-to('medium') {
        padding: 1.875rem;
        padding: 1.875rem 1.875rem env(safe-area-inset-bottom, 1.625rem);
    }

    @include respond-to('large') {
        position: absolute;
        top: 50%;
        left: 50%;
        bottom: auto;
        width: 28.125rem;
        height: 18rem;
        margin: 0;
        padding: 1.875rem;
        border-radius: $border-radius-base;
        transform: translate(-50%, -50%);
    }
}

.notificationLayoutContent {
    @include respond-to('medium') {
        position: relative;
        overflow: auto;
        height: 80%;
        margin-right: -1.875rem;
    }
}

.text {
    margin: 0 0 0.5rem 0;
    padding: 0 0.625rem;
    color: $secondary-color;

    font-size: $font-size-lg-2;
    float: none;

    @include respond-to('medium') {
        padding: 0;
    }

    @include respond-to('large') {
        width: 100%;
    }
}

.confirm-button {
    padding: 0.625rem 0.625rem 0.625rem;
    padding: 0.625rem 0.625rem calc(env(safe-area-inset-bottom, 0.625rem) + 0.625rem);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: $background-secondary;

    @include respond-to('medium') {
        position: fixed;
    }
}
