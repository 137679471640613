@import '../../styles/variables';

.root {
    min-height: 65vh;
    text-align: center;
    max-width: 29rem;
    margin: 0 auto;
}

.title {
    font-size: $font-size-lg-5;
}

.text {
    line-height: 1.5;
}

.button {
    clear: both;
    margin-top: 1rem;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;

    button {
        @include respond-to('small') {
            width: auto;
        }
    }
}

.assistSection {
    display: flex;
    align-items: center;
    color: $dark-color;
    font-size: 16px;
    margin: 2.5rem 0;

    &::before,
    &::after {
        content: '';
        flex: 1;
        height: 1px;
        background-color: $dark-color;
        margin: 0 10px;
    }
}
