@import '../../../app/styles/variables';

.root {
    display: inline-flex;
    gap: 0.125rem;
    align-items: center;
    padding: 0.125rem 0.375rem;
    border-radius: 1.5rem;
}

.notConfirmed {
    background: $warning-color-light;
}

.isConfirmed {
    background: $success-color-light;
}

.checkIcon {
    fill: red;
    height: 0.75rem;
    width: 0.75rem;
}

.label {
    font-size: $font-size-sm-2;
    line-height: 1rem;
    font-weight: $font-weight-medium;
}

.labelConfirmed {
    color: $success-color;
}

.labelNotConfirmed {
    color: $warning-color;
}

.popoverImg {
    position: relative;
    top: 0.2rem;
    width: 1rem;

    @include respond-to('large') {
        margin-right: 0.2rem;
    }

    @include respond-to('x-large') {
        margin-right: 0;
        margin-left: 0.2rem;
    }
}

.popoverTrigger {
    display: flex;
    align-items: center;
}

.confirmIcon {
    &:hover {
        fill: $warning-color;

        & path {
            stroke: white;
        }
    }
}

.mobileContent {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
