@import '../../styles/variables';

.button {
    clear: both;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;

    button {
        margin: 0;

        @include respond-to('small') {
            width: auto;
        }

        @include respond-to-reverse('medium') {
            width: 100%;
        }
    }
}

.text {
    font-size: $font-size-base;
}

.row {
    margin-top: 2rem;
    display: flex;
}

.smallRow {
    display: flex;
}

.smallRow > div {
    margin-right: 0.5rem;
}

.actionCode {
    display: flex;
    margin-top: 0.5rem;
    flex-wrap: wrap;

    @include respond-to('medium') {
        margin-top: 1.5rem;
        flex-direction: row;
    }

    > * {
        vertical-align: middle;
        display: inline-block;
        height: 40px;
        line-height: 40px;

        @include respond-to('medium') {
            margin-right: 1rem;
            margin-top: 1rem;
            height: 40px;
            line-height: 40px;
        }
    }

    > p {
        height: auto;
        line-height: 1;
        margin-bottom: 1.5rem;
        flex-basis: 100%;

        @include respond-to('medium') {
            margin-right: 1rem;
            margin-top: 1rem;
            height: 40px;
            line-height: 40px;
        }
    }
}

.paymentAdd {
    background-color: $info-color;
    color: $alternate-color;
    border-radius: $border-radius-base;
    font-size: $font-size-sm-1;
    line-height: $line-height-sm;
    font-weight: bold;
    vertical-align: middle;
    padding: 0 1rem;
    cursor: pointer;
}

.newsletter {
    font-size: $font-size-lg-2;
    color: $secondary-color;
}

.error {
    margin-top: 1.25rem;
}

.checkboxAgreements {
    align-items: flex-start;
}

.checkboxAgreementsBox {
    margin-right: 0.625rem;
}

.percentagePrice {
    font-size: $font-size-base;
    color: $valid-color;
}

.whiteBlock {
    background-color: $background-secondary;
    padding: 1.875rem;

    @include respond-to-reverse('large') {
        margin-left: -10px;
        margin-right: -10px;
    }
}

.inputLabel {
    display: flex;
    margin: 0 0 0.5rem 0;
    color: $stone;

    font-size: 1.125rem;
    font-weight: normal;
}

.informationTitle {
    font-weight: $font-weight-medium;
    font-weight: $font-size-base;
    margin-top: 52px;
    color: $stone;
}

.informationList {
    color: $stone;
    margin: 9px 0 9px 0;
    padding-left: 22px;
}

.seeMoreMethodsButton {
    font-size: 0.7rem;
    padding: 1rem 1.675rem;
}

.visaOnlyMethodSelector {
    margin-bottom: 1rem;
}

.notificationsContainer {
    display: flex;
    flex-direction: column;

    & > div {
        margin-bottom: 1rem;
    }
}

.personalDetailsCardBody {
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.backLink {
    color: $darkblue !important;
    text-decoration: underline;
}

.paymentTerms {
    a {
        color: $darkblue !important;
        text-decoration: underline;
    }
}
