@import '../../../app/styles/variables';

.green {
    color: $valid-color;
}

.blue {
    color: $blue;
}

.title {
    font-size: $font-size-lg-2;
    font-weight: 400;

    margin: 0 0 0.3rem;
    padding: 0;
    width: 60%;
    float: left;
}

.price {
    font-size: $font-size-lg-2;
    margin: 0;
    padding: 0;
    color: $secondary-color;
    text-align: right;
    font-weight: 400;
    small {
        font-size: $font-size-sm-2;
    }
}

.clear {
    clear: both;
}

.stars {
    margin-bottom: 1.75rem;
}

.button {
    width: 50%;

    * {
        margin-top: 10px;
    }
}

.buttonSelect {
    padding: 0.625rem 0.875rem;

    font-weight: normal;

    color: $blue;
}

.infoContent {
    margin: 0.5rem;
    width: 11rem;
}
