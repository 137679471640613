@import '../../../app/styles/variables';
@import '../../../app/styles/custom-properties';

.seatPicker {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    @include respond-to-reverse('medium') {
        flex-direction: column;
    }
}

.seatPicker > ul {
    flex: 2;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    margin-right: 1.8rem;

    @include respond-to-reverse('medium') {
        margin-bottom: 2rem;
    }
}

.seatPicker li {
    clear: both;
    padding-top: 1rem;
}

.seatPicker li:first-of-type {
    padding-top: 0;
}

.seatPickerTitle {
    margin: 0 0 2rem 0;
    color: $secondary-color;

    font-size: 1.125rem;
    font-weight: normal;

    @include respond-to('medium') {
        margin: 0 0 $layout-spacing-xl 0;
    }
}

.stadium {
    display: flex;
    flex-direction: column;
    border: $border-width-base solid $dark-light-color;
    border-radius: $border-radius-base;
    flex: 3;
}

.venueName {
    display: block;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 0.6rem;
    font-size: 1rem;
    color: $stone;
}

.hasImage {
    width: 100%;
}

.stadium .hasImage img {
    width: 80%;
    padding: 0;
    margin: 0;

    @include respond-to('medium') {
        width: 100%;
    }
}

.hasImage {
    padding: 1.25rem;
    display: flex;
    justify-content: center;

    @include respond-to-reverse('medium') {
        padding: 0.5rem;
    }
}

.noImage {
    box-sizing: border-box;
    text-align: center;
    width: 100%;
    margin: 2rem auto 0 auto;

    @include respond-to('medium') {
        width: 60%;
    }
}

.noImageNotification {
    color: $secondary-color;
}

.details {
    clear: both;
    width: 100%;
    padding: 1rem;

    @include respond-to('medium') {
        padding: 1.25rem;
    }

    .content {
        display: inline-block;
        width: 100%;
        vertical-align: top;
        text-align: left;

        & h2 {
            font-size: $font-size-base;
        }

        @include respond-to-reverse('medium') {
            padding-right: 0.5rem;
            padding-left: 0.5rem;
        }

        &.noImages {
            width: 100%;
        }
    }

    .seat-name {
        font-size: 1.375rem;

        margin-bottom: 0;
    }

    .images {
        display: inline-block;
        position: relative;
        margin-bottom: 1rem;

        @include respond-to('medium') {
            width: 50%;
        }

        button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            height: 2.1rem;
            width: 2.1rem;
            background-color: rgba(0, 0, 0, 0.7);
            display: none;

            @include respond-to('medium') {
                display: block;
            }

            &:hover {
                cursor: pointer;
            }

            &.left {
                left: 0;
                padding-left: 0.4rem;
                border-radius: 0 $border-radius-sm $border-radius-sm 0;
            }

            &.right {
                right: 0;
                padding-right: 0.4rem;
                border-radius: $border-radius-sm 0 0 $border-radius-sm;
            }

            img {
                display: block;
                width: auto;
                margin: auto;
            }
        }

        &-container {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            max-width: calc(100vw - 3.69rem);

            .image {
                width: 80%;
                display: inline-block;
                margin-right: 1rem;

                &:last-of-type {
                    margin-right: 0;
                }

                @include respond-to('medium') {
                    display: none;
                    width: 100%;

                    &.active {
                        display: block;
                    }
                }
            }
        }
    }
}

.highlights {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0.875rem;
    border-radius: 0 0 $border-radius-base $border-radius-base;
    background-color: $background-primary;

    & .higlightText {
        color: $background-dark;
    }

    @include respond-to('medium') {
        background-color: $background-dark;

        & .higlightText {
            color: white;
        }
    }

    @include respond-to-reverse('medium') {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
        text-align: left;
    }
}

.popOverContent {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    font-size: 0.875rem;

    &:first-of-type {
        margin-right: 0.5rem;
    }

    @include respond-to-reverse('medium') {
        @include var-prop('color', '--tco-body-color', $text-primary-color);
    }

    & span {
        display: block;
        text-align: left;
        flex: 1;

        & svg {
            margin-right: 0.5rem;
            margin-left: 0;
        }
    }

    & svg {
        height: 20px;
        flex-shrink: 0;
        fill: $blue-light;
        margin-right: 0.6rem;

        @include respond-to('medium') {
            margin-right: 0.5rem;
        }

        & image {
            height: 100%;
            width: auto;
        }
    }
}

.smallHighlightIcon {
    & svg {
        height: 24px;
    }

    & span {
        margin-right: 0.2rem;
    }
}

.chooseHotelButtonIcon {
    margin-left: 2rem;
}

.ticketDeliveryText {
    color: white;
    font-size: $font-size-sm-1;
}

.ticketDeliveryTextDays {
    color: $success-color;
}
